/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * qb-api
 * QB API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  Collection,
  CollectionImport,
  MoveTopicBody,
  Notepad,
  NotepadCreateBody,
  NotepadTag,
  NotepadUpdateBody,
  Topic
} from '.././model'
import { instance } from '.././instance';



/**
 * collections
 * @summary Retrieve all collections from the notepad for the user
 */
export const collectionsList = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<Collection[]>(
      {url: `/notepad/collections/list`, method: 'GET', signal
    },
      );
    }
  

export const getCollectionsListQueryKey = () => {
    return [`/notepad/collections/list`] as const;
    }

    
export const getCollectionsListQueryOptions = <TData = Awaited<ReturnType<typeof collectionsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof collectionsList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCollectionsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof collectionsList>>> = ({ signal }) => collectionsList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof collectionsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CollectionsListQueryResult = NonNullable<Awaited<ReturnType<typeof collectionsList>>>
export type CollectionsListQueryError = unknown

/**
 * @summary Retrieve all collections from the notepad for the user
 */
export const useCollectionsList = <TData = Awaited<ReturnType<typeof collectionsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof collectionsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCollectionsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * collection import
 * @summary Import collection
 */
export const notepadCollectionImport = (
    collectionImport: CollectionImport,
 ) => {
      
      
      return instance<Collection>(
      {url: `/notepad/collections/import`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: collectionImport
    },
      );
    }
  


export const getNotepadCollectionImportMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notepadCollectionImport>>, TError,{data: CollectionImport}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof notepadCollectionImport>>, TError,{data: CollectionImport}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof notepadCollectionImport>>, {data: CollectionImport}> = (props) => {
          const {data} = props ?? {};

          return  notepadCollectionImport(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type NotepadCollectionImportMutationResult = NonNullable<Awaited<ReturnType<typeof notepadCollectionImport>>>
    export type NotepadCollectionImportMutationBody = CollectionImport
    export type NotepadCollectionImportMutationError = unknown

    /**
 * @summary Import collection
 */
export const useNotepadCollectionImport = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notepadCollectionImport>>, TError,{data: CollectionImport}, TContext>, }
) => {

      const mutationOptions = getNotepadCollectionImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * collection template import
 * @summary Import template collection
 */
export const notepadCollectionTemplateImport = (
    collectionImport: CollectionImport,
 ) => {
      
      
      return instance<Collection>(
      {url: `/notepad/collections/template/import`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: collectionImport
    },
      );
    }
  


export const getNotepadCollectionTemplateImportMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notepadCollectionTemplateImport>>, TError,{data: CollectionImport}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof notepadCollectionTemplateImport>>, TError,{data: CollectionImport}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof notepadCollectionTemplateImport>>, {data: CollectionImport}> = (props) => {
          const {data} = props ?? {};

          return  notepadCollectionTemplateImport(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type NotepadCollectionTemplateImportMutationResult = NonNullable<Awaited<ReturnType<typeof notepadCollectionTemplateImport>>>
    export type NotepadCollectionTemplateImportMutationBody = CollectionImport
    export type NotepadCollectionTemplateImportMutationError = unknown

    /**
 * @summary Import template collection
 */
export const useNotepadCollectionTemplateImport = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notepadCollectionTemplateImport>>, TError,{data: CollectionImport}, TContext>, }
) => {

      const mutationOptions = getNotepadCollectionTemplateImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get collection
 */
export const getCollection = (
    collectionId: string,
 signal?: AbortSignal
) => {
      
      
      return instance<Collection>(
      {url: `/notepad/collections/${collectionId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetCollectionQueryKey = (collectionId: string,) => {
    return [`/notepad/collections/${collectionId}`] as const;
    }

    
export const getGetCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getCollection>>, TError = unknown>(collectionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCollection>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionQueryKey(collectionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollection>>> = ({ signal }) => getCollection(collectionId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getCollection>>>
export type GetCollectionQueryError = unknown

export const useGetCollection = <TData = Awaited<ReturnType<typeof getCollection>>, TError = unknown>(
 collectionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCollection>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionQueryOptions(collectionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * collection update
 * @summary Edit collection
 */
export const collectionUpdate = (
    collectionId: string,
    collection: Collection,
 ) => {
      
      
      return instance<Collection>(
      {url: `/notepad/collections/${collectionId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: collection
    },
      );
    }
  


export const getCollectionUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof collectionUpdate>>, TError,{collectionId: string;data: Collection}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof collectionUpdate>>, TError,{collectionId: string;data: Collection}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof collectionUpdate>>, {collectionId: string;data: Collection}> = (props) => {
          const {collectionId,data} = props ?? {};

          return  collectionUpdate(collectionId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CollectionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof collectionUpdate>>>
    export type CollectionUpdateMutationBody = Collection
    export type CollectionUpdateMutationError = unknown

    /**
 * @summary Edit collection
 */
export const useCollectionUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof collectionUpdate>>, TError,{collectionId: string;data: Collection}, TContext>, }
) => {

      const mutationOptions = getCollectionUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete collection
 */
export const deleteCollection = (
    collectionId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/notepad/collections/${collectionId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteCollectionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCollection>>, TError,{collectionId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCollection>>, TError,{collectionId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCollection>>, {collectionId: string}> = (props) => {
          const {collectionId} = props ?? {};

          return  deleteCollection(collectionId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCollection>>>
    
    export type DeleteCollectionMutationError = unknown

    export const useDeleteCollection = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCollection>>, TError,{collectionId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * collection create
 * @summary Create collection
 */
export const collectionCreate = (
    collection: Collection,
 ) => {
      
      
      return instance<Collection>(
      {url: `/notepad/collections/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: collection
    },
      );
    }
  


export const getCollectionCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof collectionCreate>>, TError,{data: Collection}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof collectionCreate>>, TError,{data: Collection}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof collectionCreate>>, {data: Collection}> = (props) => {
          const {data} = props ?? {};

          return  collectionCreate(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CollectionCreateMutationResult = NonNullable<Awaited<ReturnType<typeof collectionCreate>>>
    export type CollectionCreateMutationBody = Collection
    export type CollectionCreateMutationError = unknown

    /**
 * @summary Create collection
 */
export const useCollectionCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof collectionCreate>>, TError,{data: Collection}, TContext>, }
) => {

      const mutationOptions = getCollectionCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * notepads
 * @summary Retrieve all notepads from the user
 */
export const notepadsList = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<Notepad[]>(
      {url: `/notepad/notepads/list`, method: 'GET', signal
    },
      );
    }
  

export const getNotepadsListQueryKey = () => {
    return [`/notepad/notepads/list`] as const;
    }

    
export const getNotepadsListQueryOptions = <TData = Awaited<ReturnType<typeof notepadsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof notepadsList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getNotepadsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof notepadsList>>> = ({ signal }) => notepadsList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof notepadsList>>, TError, TData> & { queryKey: QueryKey }
}

export type NotepadsListQueryResult = NonNullable<Awaited<ReturnType<typeof notepadsList>>>
export type NotepadsListQueryError = unknown

/**
 * @summary Retrieve all notepads from the user
 */
export const useNotepadsList = <TData = Awaited<ReturnType<typeof notepadsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof notepadsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getNotepadsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get notepad
 */
export const getNotepad = (
    notepadId: string,
 signal?: AbortSignal
) => {
      
      
      return instance<Notepad>(
      {url: `/notepad/notepads/${notepadId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetNotepadQueryKey = (notepadId: string,) => {
    return [`/notepad/notepads/${notepadId}`] as const;
    }

    
export const getGetNotepadQueryOptions = <TData = Awaited<ReturnType<typeof getNotepad>>, TError = unknown>(notepadId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotepad>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNotepadQueryKey(notepadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotepad>>> = ({ signal }) => getNotepad(notepadId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(notepadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getNotepad>>, TError, TData> & { queryKey: QueryKey }
}

export type GetNotepadQueryResult = NonNullable<Awaited<ReturnType<typeof getNotepad>>>
export type GetNotepadQueryError = unknown

export const useGetNotepad = <TData = Awaited<ReturnType<typeof getNotepad>>, TError = unknown>(
 notepadId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotepad>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetNotepadQueryOptions(notepadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * notepad update
 * @summary Edit notepad
 */
export const notepadUpdate = (
    notepadId: string,
    notepadUpdateBody: NotepadUpdateBody,
 ) => {
      
      
      return instance<Notepad>(
      {url: `/notepad/notepads/${notepadId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: notepadUpdateBody
    },
      );
    }
  


export const getNotepadUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notepadUpdate>>, TError,{notepadId: string;data: NotepadUpdateBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof notepadUpdate>>, TError,{notepadId: string;data: NotepadUpdateBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof notepadUpdate>>, {notepadId: string;data: NotepadUpdateBody}> = (props) => {
          const {notepadId,data} = props ?? {};

          return  notepadUpdate(notepadId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type NotepadUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof notepadUpdate>>>
    export type NotepadUpdateMutationBody = NotepadUpdateBody
    export type NotepadUpdateMutationError = unknown

    /**
 * @summary Edit notepad
 */
export const useNotepadUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notepadUpdate>>, TError,{notepadId: string;data: NotepadUpdateBody}, TContext>, }
) => {

      const mutationOptions = getNotepadUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete notepad
 */
export const deleteNotepad = (
    notepadId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/notepad/notepads/${notepadId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteNotepadMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNotepad>>, TError,{notepadId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteNotepad>>, TError,{notepadId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNotepad>>, {notepadId: string}> = (props) => {
          const {notepadId} = props ?? {};

          return  deleteNotepad(notepadId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteNotepadMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNotepad>>>
    
    export type DeleteNotepadMutationError = unknown

    export const useDeleteNotepad = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNotepad>>, TError,{notepadId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteNotepadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * notepad create
 * @summary Create notepad
 */
export const notepadCreate = (
    notepadCreateBody: NotepadCreateBody,
 ) => {
      
      
      return instance<Notepad>(
      {url: `/notepad/notepads/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: notepadCreateBody
    },
      );
    }
  


export const getNotepadCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notepadCreate>>, TError,{data: NotepadCreateBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof notepadCreate>>, TError,{data: NotepadCreateBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof notepadCreate>>, {data: NotepadCreateBody}> = (props) => {
          const {data} = props ?? {};

          return  notepadCreate(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type NotepadCreateMutationResult = NonNullable<Awaited<ReturnType<typeof notepadCreate>>>
    export type NotepadCreateMutationBody = NotepadCreateBody
    export type NotepadCreateMutationError = unknown

    /**
 * @summary Create notepad
 */
export const useNotepadCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notepadCreate>>, TError,{data: NotepadCreateBody}, TContext>, }
) => {

      const mutationOptions = getNotepadCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * topics
 * @summary Retrieve all topics from the notepad for the user
 */
export const notepadList = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<Topic[]>(
      {url: `/notepad/topics/list`, method: 'GET', signal
    },
      );
    }
  

export const getNotepadListQueryKey = () => {
    return [`/notepad/topics/list`] as const;
    }

    
export const getNotepadListQueryOptions = <TData = Awaited<ReturnType<typeof notepadList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof notepadList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getNotepadListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof notepadList>>> = ({ signal }) => notepadList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof notepadList>>, TError, TData> & { queryKey: QueryKey }
}

export type NotepadListQueryResult = NonNullable<Awaited<ReturnType<typeof notepadList>>>
export type NotepadListQueryError = unknown

/**
 * @summary Retrieve all topics from the notepad for the user
 */
export const useNotepadList = <TData = Awaited<ReturnType<typeof notepadList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof notepadList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getNotepadListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * topic create
 * @summary Create topic
 */
export const topicCreate = (
    topic: Topic,
 ) => {
      
      
      return instance<Topic>(
      {url: `/notepad/topics/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: topic
    },
      );
    }
  


export const getTopicCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof topicCreate>>, TError,{data: Topic}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof topicCreate>>, TError,{data: Topic}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof topicCreate>>, {data: Topic}> = (props) => {
          const {data} = props ?? {};

          return  topicCreate(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type TopicCreateMutationResult = NonNullable<Awaited<ReturnType<typeof topicCreate>>>
    export type TopicCreateMutationBody = Topic
    export type TopicCreateMutationError = unknown

    /**
 * @summary Create topic
 */
export const useTopicCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof topicCreate>>, TError,{data: Topic}, TContext>, }
) => {

      const mutationOptions = getTopicCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * topic update
 * @summary Edit topic
 */
export const topicUpdate = (
    topicId: string,
    topic: Topic,
 ) => {
      
      
      return instance<Topic>(
      {url: `/notepad/topics/${topicId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: topic
    },
      );
    }
  


export const getTopicUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof topicUpdate>>, TError,{topicId: string;data: Topic}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof topicUpdate>>, TError,{topicId: string;data: Topic}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof topicUpdate>>, {topicId: string;data: Topic}> = (props) => {
          const {topicId,data} = props ?? {};

          return  topicUpdate(topicId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type TopicUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof topicUpdate>>>
    export type TopicUpdateMutationBody = Topic
    export type TopicUpdateMutationError = unknown

    /**
 * @summary Edit topic
 */
export const useTopicUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof topicUpdate>>, TError,{topicId: string;data: Topic}, TContext>, }
) => {

      const mutationOptions = getTopicUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Move topic
 */
export const moveTopic = (
    topicId: string,
    moveTopicBody: MoveTopicBody,
 ) => {
      
      
      return instance<Topic>(
      {url: `/notepad/topics/${topicId}/move`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: moveTopicBody
    },
      );
    }
  


export const getMoveTopicMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveTopic>>, TError,{topicId: string;data: MoveTopicBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof moveTopic>>, TError,{topicId: string;data: MoveTopicBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveTopic>>, {topicId: string;data: MoveTopicBody}> = (props) => {
          const {topicId,data} = props ?? {};

          return  moveTopic(topicId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type MoveTopicMutationResult = NonNullable<Awaited<ReturnType<typeof moveTopic>>>
    export type MoveTopicMutationBody = MoveTopicBody
    export type MoveTopicMutationError = unknown

    export const useMoveTopic = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveTopic>>, TError,{topicId: string;data: MoveTopicBody}, TContext>, }
) => {

      const mutationOptions = getMoveTopicMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * tag create
 * @summary Create tag
 */
export const tagCreate = (
    notepadTag: NotepadTag,
 ) => {
      
      
      return instance<NotepadTag>(
      {url: `/notepad/tags/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: notepadTag
    },
      );
    }
  


export const getTagCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tagCreate>>, TError,{data: NotepadTag}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof tagCreate>>, TError,{data: NotepadTag}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tagCreate>>, {data: NotepadTag}> = (props) => {
          const {data} = props ?? {};

          return  tagCreate(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type TagCreateMutationResult = NonNullable<Awaited<ReturnType<typeof tagCreate>>>
    export type TagCreateMutationBody = NotepadTag
    export type TagCreateMutationError = unknown

    /**
 * @summary Create tag
 */
export const useTagCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tagCreate>>, TError,{data: NotepadTag}, TContext>, }
) => {

      const mutationOptions = getTagCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    