import { forwardRef } from 'react'
import { IconProps } from '../../molecules/NavItem/NavItem'

const School = forwardRef<any, IconProps>(({ size, color, ...rest }, ref) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    ref={ref}
    fill={color}
    viewBox="0 0 32 34.839"
  >
    <path
      id="Icon_map-school"
      data-name="Icon map-school"
      d="M20.918,5.76V29.52H4.223V5.76h16.7M23.7,2.88H1.44V33.12H23.7V2.88ZM6.31,8.64H18.831v2.88H6.31Zm0,5.04H18.831v2.88H6.31Zm0,5.04H18.831V21.6H6.31Zm0,5.04H18.831v2.88H6.31ZM27.875,8.64H33.44V28.8H27.875ZM30.662.72a2.82,2.82,0,0,0-2.787,2.835V6.48H33.44V3.555A2.817,2.817,0,0,0,30.662.72Zm-2.83,30.24,2.811,4.6,2.8-4.6Z"
      transform="translate(-1.44 -0.72)"
      fill={color}
    />
  </svg>
))

export default School
