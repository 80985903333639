import { forwardRef } from 'react'
import { IconProps } from '../../molecules/NavItem/NavItem'

const Forum = forwardRef<any, IconProps>(({ size, color, ...rest }, ref) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 -960 960 960"
    ref={ref}
    fill={color}
  >
    <path
      id="Icon_metro-forum"
      data-name="Icon metro-forum"
      d="M281-240q-14 0-27.5-14T240-282v-98h500v-340h100q14 0 27 14t13 29v596L721-240H281ZM80-280v-558q0-14 13-28t27-14h519q15 0 28 13.5t13 28.5v356q0 14-13 28t-28 14H240L80-280Zm540-220v-320H140v400l74-80h406Zm-480 0v-320 320Z"
      transform="translate(-12.436 -8.571)"
      fill={color}
    />
  </svg>
))

export default Forum
