import clsx from "clsx";
import { FC, createContext, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DecksMenu, FlashcardCollectionsBoard, FlashcardsStudyBoard } from "..";
import { useFlashcardsCollectionsList } from "../../api/flashcards/flashcards";
import { Divider, Loading, Typography } from "../../atoms";
import { StudyTableDeck } from "../FlashcardsStudyBoard/FlashcardsStudyBoard";
import classes from './FlashcardsCollectionsList.module.css';

enum Pages {
    BROWSE,
    STUDY,
}

// Context for the highlighted text and pasted text
interface HighlightedTextContext {
    highlightedText: string;
    onPastedText: () => void;
}

export const HighlightedTextContext = createContext<HighlightedTextContext>({
    highlightedText: "",
    onPastedText: () => { }
})

interface FlashcardCollectionsListProps {
    addMode?: boolean;
    highlightedText?: string;
    userIsAdmin?: boolean;
    onPastedText?: () => void;
}

const FlashcardCollectionsList: FC<FlashcardCollectionsListProps> = ({
    addMode = true,
    highlightedText,
    userIsAdmin = false,
    onPastedText
}) => {

    const { t } = useTranslation()

    const highlightedTextContextValue: HighlightedTextContext = useMemo(() => ({
        highlightedText: highlightedText ?? "",
        onPastedText: onPastedText ?? (() => { })
    }), [highlightedText, onPastedText])

    const { data: collections, isLoading } = useFlashcardsCollectionsList()

    const [flashcardCollectionIdSelected, setFlashcardCollectionIdSelected] = useState<string | undefined>(undefined)
    const [currentPage, setCurrentPage] = useState<number>(Pages.BROWSE)

    const collectionSelected = useMemo(() => {
        return collections?.find((collection) => collection.id === flashcardCollectionIdSelected)
    }, [flashcardCollectionIdSelected, collections])

    const decks = useMemo(() => {
        // Return an array with every deck from every collection
        const decks: StudyTableDeck[] = []

        collections?.forEach((collection) => {
            collection.decks.forEach((deck) => {
                const newDeck: StudyTableDeck = {
                    ...deck,
                    collection_name: collection.title ?? "",
                }
                decks.push(newDeck)
            })
        })

        return decks

    }, [collections])

    return (
        <>
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <>
                        {
                            !collectionSelected ?
                                <Row className="align-items-center justify-content-start">
                                    <Col>
                                        <Button
                                            variant="default"
                                            className={clsx(
                                                classes.navigationButton,
                                                currentPage === Pages.BROWSE && classes.selected,
                                            )}
                                            onClick={() => setCurrentPage(Pages.BROWSE)}
                                        >
                                            <Typography className="mb-0" variant="h5">
                                                {t('flashcards.navigation.browse')}
                                            </Typography>
                                        </Button>
                                        <Button
                                            variant="default"
                                            className={clsx(
                                                classes.navigationButton,
                                                currentPage === Pages.STUDY && classes.selected,
                                            )}
                                            onClick={() => setCurrentPage(Pages.STUDY)}
                                        >
                                            <Typography className="mb-0" variant="h5">
                                                {t('flashcards.navigation.study')}
                                            </Typography>
                                        </Button>
                                    </Col>
                                </Row>
                                : null
                        }

                        <Divider className="mb-4" />

                        {currentPage === Pages.STUDY ? (
                            <FlashcardsStudyBoard
                                decks={decks}
                            />
                        )
                            : (
                                <HighlightedTextContext.Provider
                                    value={highlightedTextContextValue} >
                                    {
                                        collectionSelected ?
                                            <DecksMenu
                                                collection={collectionSelected}
                                                setFlashcardCollectionIdSelected={setFlashcardCollectionIdSelected}
                                            />
                                            :
                                            <FlashcardCollectionsBoard
                                                addMode={addMode}
                                                collections={collections ?? []}
                                                userIsAdmin={userIsAdmin}
                                                setFlashcardCollectionIdSelected={setFlashcardCollectionIdSelected}
                                            />
                                    }
                                </HighlightedTextContext.Provider>
                            )}
                    </>
                )
            }
        </>
    )
}

export default FlashcardCollectionsList;