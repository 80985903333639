import { FC } from 'react'
import Spinner from 'react-bootstrap/Spinner'

interface LoadingProps {
  className?: string
  size?: 'sm'
}

const Loading: FC<LoadingProps> = ({ className, size }) => {
  return (
    <Spinner
      size={size}
      className={className}
      animation="border"
      variant="success"
    />
  )
}

export default Loading
