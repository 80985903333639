import { forwardRef } from 'react'
import { IconProps } from '../../molecules/NavItem/NavItem'

const Home = forwardRef<any, IconProps>(({ size, color, ...rest }, ref) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    ref={ref}
    fill={color}
    viewBox="0 0 43.111 33.525"
  >
    <path
      id="Icon_awesome-home"
      data-name="Icon awesome-home"
      d="M20.983,10.951,7.185,22.315V34.581a1.2,1.2,0,0,0,1.2,1.2l8.387-.022a1.2,1.2,0,0,0,1.191-1.2V27.4a1.2,1.2,0,0,1,1.2-1.2h4.79a1.2,1.2,0,0,1,1.2,1.2v7.158a1.2,1.2,0,0,0,1.2,1.2l8.384.023a1.2,1.2,0,0,0,1.2-1.2V22.307l-13.8-11.356A.912.912,0,0,0,20.983,10.951Zm21.8,7.724-6.257-5.157V3.152a.9.9,0,0,0-.9-.9H31.433a.9.9,0,0,0-.9.9V8.586l-6.7-5.513a3.592,3.592,0,0,0-4.565,0L.325,18.675a.9.9,0,0,0-.12,1.265l1.908,2.32a.9.9,0,0,0,1.266.122l17.6-14.5a.912.912,0,0,1,1.145,0l17.6,14.5A.9.9,0,0,0,41,22.262l1.908-2.32a.9.9,0,0,0-.127-1.267Z"
      transform="translate(0.001 -2.254)"
      fill={color}
    />
  </svg>
))

export default Home
