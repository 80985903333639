import clsx from 'clsx'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useFlashcardUpdate } from '../../api/flashcards/flashcards'
import { Flashcard as FlashcardAPI } from '../../api/model'
import Flashcard, { Levels } from '../../atoms/Flashcard/Flashcard'
import randomIntFromInterval from '../../utils/randomIntFromInterval'
import classes from './FlashcardStudyBoard.module.css'
import getFlashcardDifficulty from '../../utils/getFlashcardDifficulty'
import { Typography } from '../../atoms'

interface FlashcardStudyBoardProps {
  flashcards: FlashcardAPI[]
  onClose: () => void
}

const FlashcardStudyBoard: FC<FlashcardStudyBoardProps> = ({
  flashcards: initialFlashcards,
  onClose,
}) => {
  const { t } = useTranslation()

  const levelsProbability = {
    [Levels.EASY]: 0.1,
    [Levels.MODERATE]: 0.35,
    [Levels.HARD]: 0.55,
  }

  const [currentCardIndex, setCurrentCardIndex] = useState<number | undefined>(
    undefined,
  )
  const [frontSideSelected, setFrontSideSelected] = useState(true)
  const [showAnswer, setShowAnswer] = useState(false)
  const [userAlreadyAnswered, setUserAlreadyAnswered] = useState(false)
  const [isLoadingNextFlashcard, setIsLoadingNextFlashcard] = useState(false)
  const flashcards = useRef<FlashcardAPI[] | undefined>(undefined)

  const currentFlashcard = useMemo(() => {
    if (flashcards.current) return flashcards.current[currentCardIndex!]
    else return undefined
  }, [currentCardIndex])

  const currentDifficulty = useMemo(() => {
    if (currentFlashcard) {
      return getFlashcardDifficulty(t, currentFlashcard)
    } else {
      return ''
    }
  }, [currentFlashcard])

  const { mutate: updateFlashcardLevel } = useFlashcardUpdate({})

  // pick random level with probabilities defined in levelsProbability
  const weightedRandomPick = () => {
    const options = Object.keys(levelsProbability)
    const probabilities = Object.values(levelsProbability)

    const totalProbability = probabilities.reduce(
      (total, prob) => total + prob,
      0,
    )
    const randomValue = Math.random() * totalProbability

    let cumulativeProbability = 0
    for (let i = 0; i < options.length; i++) {
      cumulativeProbability += probabilities[i]
      if (randomValue <= cumulativeProbability) {
        return options[i]
      }
    }
  }

  const prepareNextQuestion = () => {
    setUserAlreadyAnswered(false)
    setShowAnswer(false)
    setFrontSideSelected(true)

    if (flashcards.current!.length === 0) {
      setCurrentCardIndex(undefined)
      return
    }

    const nextFlashcardDeck: FlashcardAPI[] = []
    while (nextFlashcardDeck.length === 0) {
      const nextLevel = Number.parseInt(weightedRandomPick()!)

      flashcards.current!.forEach((card) => {
        if (card.level === nextLevel) {
          nextFlashcardDeck.push(card)
        }
      })
    }

    const nextCard =
      nextFlashcardDeck[randomIntFromInterval(0, nextFlashcardDeck.length - 1)]

    const nextCardIndex = flashcards.current!.findIndex(
      (card) => card.id === nextCard.id,
    )

    setCurrentCardIndex(nextCardIndex)

    setIsLoadingNextFlashcard(true)

    setTimeout(() => {
      setIsLoadingNextFlashcard(false)
    }, 600)
  }

  const handleReview = (level: Levels) => {
    if (userAlreadyAnswered) {
      switch (level) {
        case Levels.HARD:
          moveCardToHardLevel(currentCardIndex!)
          break
        case Levels.MODERATE:
          moveCardToMediumLevel(currentCardIndex!)
          break
        case Levels.EASY:
          moveCardToEasyLevel(currentCardIndex!)
          //removeCardFromFlashcards(currentCardIndex!) // Remove the card from the array
          break
        default:
          break
      }

      prepareNextQuestion()
    }
  }

  const moveCardToHardLevel = (index: number) => {
    if (currentFlashcard?.level !== Levels.HARD) {
      const updatedFlashcards = [...flashcards.current!]
      updatedFlashcards[index].level = Levels.HARD
      flashcards.current = updatedFlashcards

      const currentCard = flashcards.current![index]

      updateFlashcardLevel({
        flashcardId: currentCard.id!,
        data: {
          level: Levels.HARD,
        },
      })
    }
  }

  const moveCardToMediumLevel = (index: number) => {
    if (currentFlashcard?.level !== Levels.MODERATE) {
      const updatedFlashcards = [...flashcards.current!]
      updatedFlashcards[index].level = Levels.MODERATE
      flashcards.current = updatedFlashcards

      const currentCard = flashcards.current![index]

      updateFlashcardLevel({
        flashcardId: currentCard.id!,
        data: {
          level: Levels.MODERATE,
        },
      })
    }
  }

  const moveCardToEasyLevel = (index: number) => {
    if (currentFlashcard?.level !== Levels.EASY) {
      const updatedFlashcards = [...flashcards.current!]
      updatedFlashcards[index].level = Levels.EASY
      flashcards.current = updatedFlashcards

      const currentCard = flashcards.current![index]

      updateFlashcardLevel({
        flashcardId: currentCard.id!,
        data: {
          level: Levels.EASY,
        },
      })
    }
  }

  const removeCardFromFlashcards = (index: number) => {
    const updatedFlashcards = flashcards.current?.filter((_, i) => i !== index)
    flashcards.current = updatedFlashcards
  }

  const onShowAnswerButtonClicked = () => {
    if (!userAlreadyAnswered) {
      setUserAlreadyAnswered(true)
    }
    setShowAnswer(!showAnswer)
  }

  const handleKeyPressed = (e: KeyboardEvent) => {
    switch (e.key) {
      case ' ':
        onShowAnswerButtonClicked()
        break
      // Asked to be removed by the client
      /* case '1':
        handleReview(Levels.EASY)
        break
      case '2':
        handleReview(Levels.MODERATE)
        break
      case '3':
        handleReview(Levels.HARD)
        break */
      default:
        break
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPressed, true)
    return () => {
      document.removeEventListener('keyup', handleKeyPressed, true)
    }
  }, [currentCardIndex, showAnswer, frontSideSelected])

  useEffect(() => {
    // Set initial flashcards
    flashcards.current = initialFlashcards
    setCurrentCardIndex(randomIntFromInterval(0, initialFlashcards.length - 1))
  }, [initialFlashcards])

  return (
    <>
      {currentFlashcard ? (
        <>
          <Row>
            <Typography variant="h6">
              {/* {t('flashcards.study.remainingCards', {
            count: flashcards.current?.length,
          })} */}
              {t('flashcards.study.difficulty', { level: currentDifficulty })}
            </Typography>
          </Row>
          <Row className="justify-content-between align-items-center">
            <>
              <Flashcard
                frontSideText={currentFlashcard.frontside_text!}
                backSideText={currentFlashcard.backside_text!}
                frontSideSelected={frontSideSelected}
                showAnswer={showAnswer}
                setFrontSideSelected={setFrontSideSelected}
                setShowAnswer={setShowAnswer}
                setUserAlreadyAnswered={setUserAlreadyAnswered}
                isLoading={isLoadingNextFlashcard}
              />

              <div
                className={clsx(
                  classes.levelsButtonsWrapper,
                  'd-flex justify-content-center position-relative z-1',
                )}
              >
                {userAlreadyAnswered && (
                  <div>
                    <Button
                      className="m-2"
                      variant="success"
                      onClick={() => handleReview(Levels.EASY)}
                    >
                      {t('flashcards.study.easy')}
                    </Button>
                    <Button
                      className="m-2"
                      variant="warning"
                      onClick={() => handleReview(Levels.MODERATE)}
                    >
                      {t('flashcards.study.moderate')}
                    </Button>
                    <Button
                      className="m-2"
                      variant="danger"
                      onClick={() => handleReview(Levels.HARD)}
                    >
                      {t('flashcards.study.hard')}
                    </Button>
                  </div>
                )}
              </div>
            </>
          </Row>
        </>
      ) : (
        <Typography variant="h6">{t('flashcards.study.noCards')}</Typography>
      )}
    </>
  )
}

export default FlashcardStudyBoard
