import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { v4 as uuid } from 'uuid'

import useInterval from '../hooks/useInterval'

const INTERVAL = 1000
const DEFAULT_TIMEOUT = 3000
const MAX_NOTIFICATION = 3

type Variant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'

export interface Notification {
  id: string
  title?: string | ReactNode
  message: string | ReactNode
  variant: Variant
  timestamp: number
  timeout?: number | null // pass in null for no timeout
}

type OptionalExceptFor<T, TRequired extends keyof T> = Partial<T> &
  Pick<T, TRequired>

type AddNotification = OptionalExceptFor<Notification, 'message'>

const defaultApi = {
  notifications: [] as Notification[],
  setNotification: (notification: AddNotification) => null,
  clearNotification: (id?: string | string[]) => null,
}

export type NotificationsContextApi = typeof defaultApi

export const NotificationsContext =
  createContext<NotificationsContextApi>(defaultApi)

export function NotificationsProvider({ children }: any) {
  const [notifications, setNotifications] = useState<Notification[]>([])

  const clearNotification = useCallback((id?: string | string[]) => {
    setNotifications((prev) => {
      if (!id) {
        return []
      } else {
        const ids = Array.isArray(id) ? id : [id]
        return prev.filter(({ id: prevId }) => !ids.includes(prevId))
      }
    })
  }, [])

  const setNotification = useCallback((notification: AddNotification) => {
    setNotifications((prev) => {
      const existing = prev.find((n) => n.id === notification.id)

      return existing
        ? prev.map((n) =>
            n.id === notification.id ? { ...existing, ...notification } : n,
          )
        : prev.concat({
            id: uuid(),
            timestamp: new Date().getTime(),
            variant: 'danger',
            ...notification,
          })
    })
  }, [])

  useEffect(() => {
    if (notifications.length > MAX_NOTIFICATION) {
      clearNotification(notifications[0]?.id)
    }
  }, [notifications, clearNotification])

  const handleExpireNotifications = useCallback(
    (currentTime) => {
      if (notifications.length) {
        const expiredIds = notifications.reduce((acc, n) => {
          const isExpired =
            n.timestamp <= currentTime - (n?.timeout || DEFAULT_TIMEOUT)

          return isExpired && n.timeout !== null ? acc.concat(n.id) : acc
        }, [] as string[])
        if (expiredIds.length) {
          clearNotification(expiredIds)
        }
      }
    },
    [notifications, clearNotification],
  )

  useInterval(handleExpireNotifications, INTERVAL)

  const notificationSetters = useMemo(
    () => ({ notifications, setNotification, clearNotification }),
    [setNotification, clearNotification, notifications],
  )

  return (
    <NotificationsContext.Provider
      value={notificationSetters as NotificationsContextApi}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export function useNotifications() {
  return useContext(NotificationsContext)
}
