import clsx from "clsx";
import * as material from 'material-colors';
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, Modal, Overlay, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { CloudArrowUp, Download, Eye, FolderPlus, Pencil, ThreeDotsVertical, Trash, Upload } from "react-bootstrap-icons";
import { CirclePicker } from "react-color";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDeleteFlashcardCollection, useFlashcardCollectionCreate, useFlashcardCollectionImport, useFlashcardCollectionTemplateImport, useFlashcardCollectionUpdate } from "../../api/flashcards/flashcards";
import { FlashcardCollection, FlashcardCollectionImport } from "../../api/model";
import { TableCell, Typography } from "../../atoms";
import SearchBar from "../../atoms/SearchBar/SearchBar";
import { Table } from "../../molecules";
import ColorPicker from "../../molecules/ColorPicker/ColorPicker";
import { exportFlashcardCollection, importFlashcardCollection } from "../../utils/flashcardExportImport";
import './FlashcardCollectionsBoard.css';
import classes from './FlashcardCollectionsBoard.module.css';

type CollectionFormValues = {
    flashcardCollectionName: string
}

const tableHeadings = [
    {
        slug: 'deck-heading',
        content: 'flashcards.table.name',
    },
    {
        slug: 'size-heading',
        content: 'flashcards.table.size',
    },
    {
        slug: 'actions-heading',
        content: 'flashcards.table.actions',
    },
]

interface FlashcardCollectionBoardProps {
    collections: FlashcardCollection[]
    addMode?: boolean
    userIsAdmin?: boolean
    setFlashcardCollectionIdSelected: (flashcardCollectionId?: string) => void
}

const FlashcardCollectionsBoard: FC<FlashcardCollectionBoardProps> = ({
    collections,
    addMode = true,
    userIsAdmin = false,
    setFlashcardCollectionIdSelected
}) => {
    const { t } = useTranslation()

    const { handleSubmit } = useForm()

    const buttonRefs = useRef<HTMLButtonElement[]>([]);
    const fileImportCollectionInputRef = useRef<HTMLInputElement>(null);
    const fileImportTemplateCollectionInputRef = useRef<HTMLInputElement>(null);

    const defaultFlashcardCollectionName = t("flashcards.collections.defaultName")
    const defaultFlashcardCollectionColor = material.lightBlue['500'].toString()

    const initialFlashcardCollectionIdx = 0

    const [flashcardCollectionName, setFlashcardCollectionName] = useState<string | undefined>(undefined)
    const [flashcardCollectionColor, setFlashcardCollectionColor] = useState<string | undefined>(
        defaultFlashcardCollectionColor,
    )

    const [searchQuery, setSearchQuery] = useState<string>('')

    const [flashcardCollectionIdxSelected, setFlashcardCollectionIdxSelected] = useState<number | undefined>(initialFlashcardCollectionIdx)
    const [showEditFlashcardCollectionModal, setShowEditFlashcardCollectionModal] = useState(false)

    const [showColorPicker, setShowColorPicker] = useState(false)
    const [duplicatedFlashcardCollectionName, setDuplicatedFlashcardCollectionName] = useState<boolean>(false)

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [showCollectionMenuTooltips, setShowCollectionMenuTooltips] = useState<boolean[]>([]);

    const { mutate: createFlashcardCollectionAPI } = useFlashcardCollectionCreate({
        mutation: {
            onSuccess: (data) => {
                collections.push(data)
                onFlashcardCollectionChanged()
            },
        },
    })

    const { mutate: importFlashcardCollectionAPI } = useFlashcardCollectionImport({
        mutation: {
            onSuccess: (data) => {
                collections.push(data)
                onFlashcardCollectionChanged()
                alert(t("flashcards.collections.importSuccess", { collection: data.title }))
            },
            onError: () => {
                alert(t("flashcards.errors.importCollectionError"))
            },
        },
    })

    const { mutateAsync: importTemplateFlashcardCollectionAPI } = useFlashcardCollectionTemplateImport({
        mutation: {
            onSuccess: (data) => {
                collections.push(data)
                onFlashcardCollectionChanged()
                alert(t("flashcards.collections.importTemplateSuccess"))
            },
        },
    })

    const { mutate: updateFlashcardCollectionAPI } = useFlashcardCollectionUpdate({
        mutation: {
            onSuccess: (data) => {
                currentFlashcardCollection!.title = data.title
                currentFlashcardCollection!.color = data.color
                onFlashcardCollectionChanged()
            },
        },
    })

    const { mutate: deleteFlashcardCollectionAPI } = useDeleteFlashcardCollection({
        mutation: {
            onSuccess: () => {
                deleteFlashcardCollection()
            },
        },
    })

    const onFlashcardCollectionChanged = () => {
        setShowEditFlashcardCollectionModal(false)
        setShowCollectionMenuTooltips(new Array(collections.length).fill(false))
    }

    const deleteFlashcardCollection = () => {
        collections.splice(flashcardCollectionIdxSelected!, 1)
        setShowDeleteModal(false)
        setFlashcardCollectionIdxSelected(undefined)
    }

    const handleTooltipButtonClick = (collectionIdx?: number) => {
        const newTooltipsState = showCollectionMenuTooltips.map((tooltip, index) => {
            return index === collectionIdx ? !tooltip : false;
        });

        setShowCollectionMenuTooltips(newTooltipsState);
    };

    const createNewFlashcardCollection = () => {
        setFlashcardCollectionIdxSelected(undefined)
        setShowEditFlashcardCollectionModal(true)
    }

    const onCancelCreateFlashcardCollection = () => {
        setShowColorPicker(false)
        setShowEditFlashcardCollectionModal(false)
        setDuplicatedFlashcardCollectionName(false)
        setFlashcardCollectionIdxSelected(undefined)
        setFlashcardCollectionName(undefined)
        setFlashcardCollectionColor(defaultFlashcardCollectionColor)
    }

    const checkIfCollectionNameAlredyInUse = (newName: string, flashcardCollection?: FlashcardCollection) => {
        let flashcardCollectionNameAlreadyInUse = false

        // check if the collection name is already in use and it's not the same collection
        if (!flashcardCollection) {
            flashcardCollectionNameAlreadyInUse = collections.some(
                (item) => item.title === newName,
            )
        } else {
            flashcardCollectionNameAlreadyInUse = collections.some(
                (item) => item.title === newName && item.id !== flashcardCollection.id,
            )
        }

        return flashcardCollectionNameAlreadyInUse
    }

    const onSubmitFlashcardCollectionEdit: SubmitHandler<CollectionFormValues> = async () => {
        const color = flashcardCollectionColor ?? defaultFlashcardCollectionColor

        let name = defaultFlashcardCollectionName

        if (flashcardCollectionName) {
            name = flashcardCollectionName
        }

        const flashcardCollectionNameAlreadyInUse = checkIfCollectionNameAlredyInUse(
            name,
            currentFlashcardCollection,
        )

        if (flashcardCollectionNameAlreadyInUse) {
            setDuplicatedFlashcardCollectionName(true)
            return
        }

        setDuplicatedFlashcardCollectionName(false)

        // If it's editing an existing flashcards
        if (currentFlashcardCollection) {
            updateFlashcardCollectionAPI({
                collectionId: currentFlashcardCollection!.id!,
                data: { ...currentFlashcardCollection, title: name, color: color },
            })
        }
        // If it's creating a new flashcards collection
        else {
            const newFlashcardCollection: FlashcardCollection = {
                title: name,
                color: color,
                decks: [],
            }

            createFlashcardCollectionAPI({ data: newFlashcardCollection })
        }

        setFlashcardCollectionName(undefined)
        setFlashcardCollectionColor(defaultFlashcardCollectionColor)
    }

    const handleExport = (collection: FlashcardCollection) => {
        let json;
        try {
            json = exportFlashcardCollection(collection);
        } catch (e) {
            alert(t("flashcards.errors.exportCollectionError"))
            console.error(e);
            return;
        }

        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');

        // Replace any special characters in the collection title with underscores and make it lowercase
        // Append the timestamp to the filename to prevent overwriting existing files

        let newName = collection.title!.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        newName = newName + '_' + new Date().getTime();

        a.href = url;
        a.download = newName
        a.click();

        URL.revokeObjectURL(url);
    };

    const handleImport = (file?: File) => {
        if (file) {
            let importedCollection: FlashcardCollection | null = null
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.result) {
                    try {
                        importedCollection = importFlashcardCollection(reader.result as string);
                        if (importedCollection) {
                            importFlashcardCollectionAPI({ data: importedCollection as FlashcardCollectionImport })
                        } else {
                            alert(t("flashcards.errors.importCollectionError"))
                        }
                    } catch (e) {
                        alert(t("flashcards.errors.importCollectionError"))
                        console.error(e);
                        return
                    }
                }
            };
            reader.readAsText(file);
        }
    };

    const handleTemplateImport = (file?: File) => {
        if (file) {
            let importedCollection: FlashcardCollection | null = null
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.result) {
                    try {
                        importedCollection = importFlashcardCollection(reader.result as string);
                        if (importedCollection) {
                            // async function because this function takes a while to execute
                            importTemplateFlashcardCollectionAPI({ data: importedCollection as FlashcardCollectionImport })
                            alert(t("flashcards.collections.importTemplateBegin"))
                        } else {
                            alert(t("flashcards.errors.importCollectionError"))
                        }
                    } catch (e) {
                        alert(t("flashcards.errors.importCollectionError"))
                        console.error(e);
                        return
                    }
                }
            };
            reader.readAsText(file);
        }
    };

    const onImportFlashcardCollectionClicked = (file?: File) => {
        handleImport(file)
    }

    const onImportTemplateFlashcardCollectionClicked = (file?: File) => {
        handleTemplateImport(file)
    }

    const onExportFlashcardCollectionClicked = (collection: FlashcardCollection) => {
        handleExport(collection)
        handleTooltipButtonClick()
    }

    const onEditFlashcardCollectionClicked = (index?: number) => {
        setFlashcardCollectionIdxSelected(index)

        const currentFlashcardCollection = collections[index!]

        setFlashcardCollectionName(currentFlashcardCollection.title)
        setFlashcardCollectionColor(currentFlashcardCollection.color)
        setShowEditFlashcardCollectionModal(true)

        handleTooltipButtonClick()
    }

    const onDeleteFlashcardCollectionClicked = (index: number) => {
        setShowDeleteModal(true)
        setFlashcardCollectionIdxSelected(index)
        handleTooltipButtonClick()
    }

    const filteredCollections = useMemo(() => {
        let filteredCollections = collections

        if (searchQuery) {
            filteredCollections = collections?.filter((collection) =>
                collection.title?.toLowerCase().includes(searchQuery.toLowerCase())
            )
        }

        return filteredCollections ?? []
    }, [collections, searchQuery])

    const currentFlashcardCollection = useMemo(() => {
        if (flashcardCollectionIdxSelected === undefined) {
            return undefined
        }
        return filteredCollections[flashcardCollectionIdxSelected]
    }, [filteredCollections, flashcardCollectionIdxSelected])

    useEffect(() => {
        const tooltips = new Array(collections.length).fill(false)

        setShowCollectionMenuTooltips(tooltips)
    }, [collections])

    return (
        <>
            <Row className="mb-4">
                <Col className="d-flex align-items-center col-auto">
                    <SearchBar
                        placeholderText={t('flashcards.collections.search')}
                        className={clsx(classes.searchBar, 'me-2')}
                        onSearch={setSearchQuery}
                        searchQuery={searchQuery}
                    />
                </Col >
                {
                    addMode && (
                        <Col>
                            <OverlayTrigger
                                key="add-new-collection-tooltip"
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-view-collection`}>
                                        {t('flashcards.collections.create')}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    variant="none"
                                    onClick={() => createNewFlashcardCollection()}
                                >
                                    <FolderPlus size={18} />
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key="import-new-collection-tooltip"
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-view-collection`}>
                                        {t('flashcards.collections.import')}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    variant="none"
                                    onClick={() => fileImportCollectionInputRef.current?.click()}
                                >
                                    <Upload size={18} />
                                </Button>
                            </OverlayTrigger>
                            <input
                                ref={fileImportCollectionInputRef}
                                type="file"
                                multiple={false}
                                accept=".json"
                                onChange={(e) => {
                                    onImportFlashcardCollectionClicked(e.target.files?.[0])
                                }}
                                hidden
                            />
                            {userIsAdmin && (
                                <>
                                    <OverlayTrigger
                                        key="import-new-template-collection-tooltip"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-view-collection`}>
                                                {t('flashcards.collections.importTemplate')}
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            variant="none"
                                            onClick={() => fileImportTemplateCollectionInputRef.current?.click()}
                                        >
                                            <CloudArrowUp size={18} />
                                        </Button>
                                    </OverlayTrigger>
                                    <input
                                        ref={fileImportTemplateCollectionInputRef}
                                        type="file"
                                        multiple={false}
                                        accept=".json"
                                        onChange={(e) => {
                                            onImportTemplateFlashcardCollectionClicked(e.target.files?.[0])
                                        }}
                                        hidden
                                    />
                                </>
                            )}
                        </Col>
                    )
                }
            </Row >
            {collections?.length === 0 ?
                <div className="text-center">
                    <Typography variant="span" className="text-muted">
                        {t('flashcards.collections.empty')}
                    </Typography>
                </div>
                : (
                    <Table headings={tableHeadings}>
                        {
                            filteredCollections && filteredCollections.length > 0 ? (
                                filteredCollections.map(
                                    (collection, collectionIdx) =>
                                        !(!addMode && collection.decks.length === 0) && (

                                            <tr
                                                key={collection.id}
                                                className={classes.collectionRow}
                                            >
                                                <TableCell>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className={clsx(classes.circle, 'mr-1')}
                                                            style={{
                                                                backgroundColor: collection.color,
                                                                width: 15,
                                                                height: 15,
                                                                marginRight: 10,
                                                                padding: 5,
                                                                border: '0.5px solid rgba(0, 0, 0, 0.176)',
                                                            }}
                                                        />
                                                        <div
                                                            onClick={() => setFlashcardCollectionIdSelected(collection.id)}
                                                            style={{ cursor: 'pointer', userSelect: 'none' }}
                                                        >
                                                            {collection.title}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>{collection.decks?.length}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="none"
                                                        ref={(ref: HTMLButtonElement) => (buttonRefs.current[collectionIdx] = ref)}
                                                        onClick={() => handleTooltipButtonClick(collectionIdx)}
                                                    >
                                                        <ThreeDotsVertical />
                                                    </Button>

                                                    <Overlay
                                                        show={showCollectionMenuTooltips[collectionIdx]}
                                                        target={buttonRefs.current[collectionIdx]}
                                                        placement="top"
                                                        containerPadding={20}
                                                        onHide={() => setShowCollectionMenuTooltips((prev) =>
                                                            prev.map((tooltip, index) => (index === collectionIdx ? false : tooltip))
                                                        )}
                                                        rootClose={true}
                                                    >
                                                        <Tooltip
                                                            id={`tooltip-${collectionIdx}`}
                                                            onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}

                                                            className={"custom-tooltip d-flex flex-column p-2 text-dark"}
                                                        >
                                                            <>
                                                                <div
                                                                    className={clsx(classes.tooltipItem, "d-flex align-items-center")}
                                                                    onClick={() => setFlashcardCollectionIdSelected(collection.id)}
                                                                >
                                                                    <Eye className={classes.icon} />
                                                                    {t('common.view')}
                                                                </div>
                                                                <div
                                                                    className={clsx(classes.tooltipItem, "d-flex align-items-center")}
                                                                    onClick={() => onExportFlashcardCollectionClicked(collection)}
                                                                >
                                                                    <Download className={classes.icon} />
                                                                    {t('common.export')}
                                                                </div>
                                                                <div
                                                                    className={clsx(classes.tooltipItem, "d-flex align-items-center")}
                                                                    onClick={() => onEditFlashcardCollectionClicked(collectionIdx)}>
                                                                    <Pencil className={classes.icon} />
                                                                    {t('common.edit')}
                                                                </div>
                                                                <div
                                                                    className={clsx(classes.tooltipItem, "d-flex align-items-center")}
                                                                    onClick={() => onDeleteFlashcardCollectionClicked(collectionIdx)}
                                                                >
                                                                    <Trash className={classes.icon} />
                                                                    {t('common.delete')}
                                                                </div>
                                                            </>
                                                        </Tooltip>
                                                    </Overlay>
                                                </TableCell>
                                            </tr>
                                        )
                                )
                            ) : (
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        <Typography variant="span" className="text-muted">
                                            {t('flashcards.collections.empty')}
                                        </Typography>
                                    </td>
                                </tr>
                            )
                        }
                    </Table>
                )}
            <Modal show={showEditFlashcardCollectionModal} onHide={onCancelCreateFlashcardCollection} centered>
                <Modal.Header>
                    <Modal.Title>
                        {flashcardCollectionIdxSelected !== undefined
                            ? t('flashcards.collections.edit') + ` \"${currentFlashcardCollection?.title}"`
                            : t('flashcards.collections.create')}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmitFlashcardCollectionEdit)}>
                    <Modal.Body>
                        <div>
                            <Form.Label>{t('common.name')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={defaultFlashcardCollectionName}
                                value={flashcardCollectionName}
                                onChange={(e) => {
                                    setFlashcardCollectionName(e.target.value)
                                }}
                            />
                            {duplicatedFlashcardCollectionName && (
                                <Form.Text className="text-danger">
                                    {t('flashcards.errors.duplicatedFlashcardCollectionName')}
                                </Form.Text>
                            )}
                        </div>
                        <div className="d-flex align-items-center mt-4 mb-2">
                            <div>{t('common.color')}</div>
                            <Button
                                variant="white"
                                className={clsx(classes.flashcardCollectionColorPickerContainer)}
                                onClick={() => setShowColorPicker(!showColorPicker)}
                            >
                                <div
                                    className={clsx(classes.circle)}
                                    style={{
                                        backgroundColor: flashcardCollectionColor,
                                        width: 15,
                                        height: 15,
                                        border: '0.5px solid rgb(33, 37, 41)',
                                    }}
                                />
                            </Button>
                        </div>
                        {showColorPicker && (
                            <ColorPicker
                                colorPickerComponent={
                                    <CirclePicker
                                        show={showColorPicker}
                                        className={clsx(classes.colorPicker)}
                                        color={flashcardCollectionColor}
                                        colors={[
                                            material.red['500'],
                                            material.pink['500'],
                                            material.purple['500'],
                                            material.lightBlue['500'],
                                            material.teal['500'],
                                            material.lightGreen['500'],
                                            material.yellow['500'],
                                            material.orange['500'],
                                        ]}
                                        // @ts-ignore
                                        onChangeComplete={(color: {
                                            rgb: { r: number; g: number; b: number; a: number }
                                        }) => {
                                            const rgbColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`
                                            setFlashcardCollectionColor(rgbColor)
                                        }}
                                    />
                                }
                                show={showColorPicker}
                                onClose={() => setShowColorPicker(false)}
                            />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="dark" onClick={() => onCancelCreateFlashcardCollection()}>
                            {t('common.cancel')}
                        </Button>
                        <Button variant="success" type="submit">
                            {t('common.save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{t('flashcards.collections.delete')} "{currentFlashcardCollection?.title}"
                    </Modal.Title>
                </Modal.Header >
                <Modal.Body>
                    {t('flashcards.collections.deleteDescription')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="dark"
                        onClick={() => {
                            setShowDeleteModal(false)
                        }}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setFlashcardCollectionName(undefined)
                            setFlashcardCollectionColor(defaultFlashcardCollectionColor)
                            deleteFlashcardCollectionAPI({ collectionId: currentFlashcardCollection!.id! })
                        }
                        }
                    >
                        {t('common.delete')}
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default FlashcardCollectionsBoard