import * as CSS from 'csstype'
import { useEffect, useRef } from 'react'
import { HighlightRange } from '../lib/highlight-range'
import { convertStyle } from './utils'

const hr = HighlightRange()

export interface HighlightRangeConfigs {
  readerRef: React.MutableRefObject<HTMLElement | null>
  highlightableContainerId?: string
  tagName?: string
  attributes?: { [key: string]: string }
  styles?: CSS.Properties<string>
  stateChangeCallback: (info: {
    selecting?: boolean
    selectedId?: string | null
    selectedStyles?: CSS.Properties<string> | null
    selectedNote?: string | null
  }) => void
}

export const useHighlightRange = ({
  readerRef,
  highlightableContainerId = 'highlightable',
  tagName = 'span',
  attributes = {},
  styles = {},
  stateChangeCallback,
}: HighlightRangeConfigs) => {
  const selectingRef = useRef(false)
  const selectedIdRef = useRef<string | null>(null)

  const cancel = () => {
    stateChangeCallback({
      selecting: (selectingRef.current = false),
      selectedId: (selectedIdRef.current = null),
      selectedStyles: null,
      selectedNote: null,
    })
  }

  const updateStyle = (newStyles: CSS.Properties<string>) => {
    if (!readerRef.current || !selectedIdRef.current) return
    const selector = `[highlightid="${selectedIdRef.current}"]`
    const nodes = readerRef.current.querySelectorAll(selector)
    const cssText = convertStyle('toCss', newStyles)
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i] as HTMLElement
      node.style.cssText = cssText
    }
  }

  const updateNote = (note: string) => {
    if (!readerRef.current || !selectedIdRef.current) return
    const selector = `[highlightid="${selectedIdRef.current}"]`
    const nodes = readerRef.current.querySelectorAll(selector)
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i] as HTMLElement
      node.setAttribute('highlightnote', note)
    }
  }

  const highlight = (canHighlight: boolean, range?: Range) => {
    let highlightId = undefined
    let attrs = attributes

    if (canHighlight) {
      attrs = { ...attrs, style: convertStyle('toCss', styles) }
    }

    let selection = null

    if (!range) {
      selection = window.getSelection()

      if (!selection || selection.isCollapsed) return

      range = selection.getRangeAt(0)
    }

    highlightId = hr.makeHighlight(range, tagName, attrs, canHighlight)

    if (!highlightId) return

    stateChangeCallback({
      selecting: (selectingRef.current = false),
      selectedId: (selectedIdRef.current = null),
    })

    return { range, highlightId }
  }

  const removeHighlight = () => {
    if (!selectedIdRef.current) return
    const highlightedNodeParent = hr.removeHighlight(
      selectedIdRef.current,
      document.getElementById(highlightableContainerId)!,
    )
    stateChangeCallback({
      selecting: (selectingRef.current = false),
      selectedId: (selectedIdRef.current = null),
      selectedStyles: null,
      selectedNote: null,
    })

    return highlightedNodeParent
  }

  const removeAllHighlights = () => {
    hr.removeAllHighlights()

    stateChangeCallback({
      selecting: (selectingRef.current = false),
      selectedId: (selectedIdRef.current = null),
      selectedStyles: null,
      selectedNote: null,
    })
  }

  useEffect(() => {
    if (!readerRef.current) return
    const element = readerRef.current

    const selectionHandler = () => {
      const selection = window.getSelection()
      if (selectingRef.current || !selection || selection.isCollapsed) return
      stateChangeCallback({ selecting: (selectingRef.current = true) })
    }

    const clickHandler = (event: Event) => {
      const target = event.target as HTMLElement
      if (target && target.hasAttribute('highlighted')) {
        const highlightId = target.getAttribute('highlightid')
        if (!highlightId || selectedIdRef.current === highlightId) return
        const css = target.getAttribute('style')
        const styleObject = css ? convertStyle('toObject', css) : null
        const note = target.getAttribute('highlightnote')
        stateChangeCallback({
          selectedId: (selectedIdRef.current = highlightId),
          selectedStyles: styleObject,
          selectedNote: note,
        })
      } else {
        const selection = window.getSelection()
        selection &&
          selection.isCollapsed &&
          (selectingRef.current || selectedIdRef.current) &&
          stateChangeCallback({
            selecting: (selectingRef.current = false),
            selectedId: (selectedIdRef.current = null),
            selectedStyles: null,
            selectedNote: null,
          })
      }
    }

    document.addEventListener('selectionchange', selectionHandler)
    element.addEventListener('click', clickHandler)

    return () => {
      document.removeEventListener('selectionchange', selectionHandler)
      element.removeEventListener('click', clickHandler)
    }
  }, [readerRef, stateChangeCallback])

  return {
    cancel,
    highlight,
    removeHighlight,
    updateStyle,
    updateNote,
    removeAllHighlights,
  }
}
