import clsx from 'clsx'
import { FC } from 'react'
import classes from './Divider.module.css'

interface DividerProps {
  className?: string
}

const Divider: FC<DividerProps> = ({ className }) => (
  <hr className={clsx(classes.hr, className)} />
)

export default Divider
