import { FileText, Images, Lightning } from 'react-bootstrap-icons'
import {
  BooksIcon,
  CalendarIcon,
  HistoryIcon,
  SchoolIcon,
  StatsIcon,
} from '../assets/icons'

const navItems = [
  {
    slug: 'nav-create-exam',
    description: 'navigation.createExam',
    to: '/create-exam',
    icon: BooksIcon,
  },
  {
    slug: 'nav-simulate-exam',
    description: 'navigation.simulateExam',
    to: '/simulate-exam',
    icon: SchoolIcon,
  },
  {
    slug: 'nav-history',
    description: 'navigation.madeExams',
    to: '/history',
    icon: HistoryIcon,
  },
  {
    slug: 'nav-statistics',
    description: 'navigation.statistics',
    to: '/statistics',
    icon: StatsIcon,
  },
  {
    slug: 'nav-flashcards',
    description: 'navigation.flashcards',
    to: '/flashcards',
    icon: Lightning,
  },
  // {
  //   slug: 'nav-notepad',
  //   description: 'navigation.notepad',
  //   to: '/notepad',
  //   icon: FileText,
  // },
  // {
  //   slug: 'nav-tables',
  //   description: 'navigation.tables',
  //   to: '/tables',
  //   icon: Images,
  // },
  {
    slug: 'nav-study-calendar',
    description: 'navigation.studyCalendar',
    to: '/study-calendar',
    icon: CalendarIcon,
  },
]

export default navItems
