import { ReactNode, FC } from 'react'
import ModalBs, { ModalProps as ModalBsProps } from 'react-bootstrap/Modal'

export interface ModalProps extends ModalBsProps {
  title?: string
  body?: string | ReactNode
  footer?: ReactNode
  closeButton?: boolean
}

const Modal: FC<ModalProps> = ({
  title,
  body,
  footer,
  closeButton,
  ...rest
}) => {
  return (
    <ModalBs {...rest} centered>
      {title && (
        <ModalBs.Header closeButton={closeButton}>
          <ModalBs.Title id="modal-title">{title}</ModalBs.Title>
        </ModalBs.Header>
      )}
      <ModalBs.Body>{body}</ModalBs.Body>
      {footer ? <ModalBs.Footer>{footer}</ModalBs.Footer> : null}
    </ModalBs>
  )
}

export default Modal
