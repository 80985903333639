/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * qb-api
 * QB API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  OverallQuestionsStatistics200Item,
  UserExamsStatistics200Item,
  UserInfo200,
  UserQuestionsStatistics200,
  UserQuestionsStatisticsParams,
  UserSubscriptionPlans200Item
} from '.././model'
import { instance } from '.././instance';



/**
 * Endpoint that retrieves user info
 * @summary User info
 */
export const userInfo = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<UserInfo200>(
      {url: `/user`, method: 'GET', signal
    },
      );
    }
  

export const getUserInfoQueryKey = () => {
    return [`/user`] as const;
    }

    
export const getUserInfoQueryOptions = <TData = Awaited<ReturnType<typeof userInfo>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userInfo>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userInfo>>> = ({ signal }) => userInfo(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type UserInfoQueryResult = NonNullable<Awaited<ReturnType<typeof userInfo>>>
export type UserInfoQueryError = unknown

/**
 * @summary User info
 */
export const useUserInfo = <TData = Awaited<ReturnType<typeof userInfo>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userInfo>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserInfoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * The skip tutorial endpoint
 * @summary Skip tutorial
 */
export const skipTutorial = (
    
 ) => {
      
      
      return instance<void>(
      {url: `/user/skip-tutorial`, method: 'POST'
    },
      );
    }
  


export const getSkipTutorialMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof skipTutorial>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof skipTutorial>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof skipTutorial>>, void> = () => {
          

          return  skipTutorial()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SkipTutorialMutationResult = NonNullable<Awaited<ReturnType<typeof skipTutorial>>>
    
    export type SkipTutorialMutationError = unknown

    /**
 * @summary Skip tutorial
 */
export const useSkipTutorial = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof skipTutorial>>, TError,void, TContext>, }
) => {

      const mutationOptions = getSkipTutorialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Activate User Plan 
 */
export const activatePlan = (
    planId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/user/activate-plan/${planId}`, method: 'PUT'
    },
      );
    }
  


export const getActivatePlanMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activatePlan>>, TError,{planId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof activatePlan>>, TError,{planId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activatePlan>>, {planId: string}> = (props) => {
          const {planId} = props ?? {};

          return  activatePlan(planId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ActivatePlanMutationResult = NonNullable<Awaited<ReturnType<typeof activatePlan>>>
    
    export type ActivatePlanMutationError = unknown

    export const useActivatePlan = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activatePlan>>, TError,{planId: string}, TContext>, }
) => {

      const mutationOptions = getActivatePlanMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get Overall Questions Statistics
 * @summary Your GET endpoint
 */
export const overallQuestionsStatistics = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<OverallQuestionsStatistics200Item[]>(
      {url: `/user/questions-stats`, method: 'GET', signal
    },
      );
    }
  

export const getOverallQuestionsStatisticsQueryKey = () => {
    return [`/user/questions-stats`] as const;
    }

    
export const getOverallQuestionsStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof overallQuestionsStatistics>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof overallQuestionsStatistics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOverallQuestionsStatisticsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof overallQuestionsStatistics>>> = ({ signal }) => overallQuestionsStatistics(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof overallQuestionsStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type OverallQuestionsStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof overallQuestionsStatistics>>>
export type OverallQuestionsStatisticsQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useOverallQuestionsStatistics = <TData = Awaited<ReturnType<typeof overallQuestionsStatistics>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof overallQuestionsStatistics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOverallQuestionsStatisticsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get a User Questions Statistics
 * @summary Your GET endpoint
 */
export const userQuestionsStatistics = (
    params?: UserQuestionsStatisticsParams,
 signal?: AbortSignal
) => {
      
      
      return instance<UserQuestionsStatistics200>(
      {url: `/user/user-questions-stats`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getUserQuestionsStatisticsQueryKey = (params?: UserQuestionsStatisticsParams,) => {
    return [`/user/user-questions-stats`, ...(params ? [params]: [])] as const;
    }

    
export const getUserQuestionsStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof userQuestionsStatistics>>, TError = unknown>(params?: UserQuestionsStatisticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userQuestionsStatistics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserQuestionsStatisticsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userQuestionsStatistics>>> = ({ signal }) => userQuestionsStatistics(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userQuestionsStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type UserQuestionsStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof userQuestionsStatistics>>>
export type UserQuestionsStatisticsQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useUserQuestionsStatistics = <TData = Awaited<ReturnType<typeof userQuestionsStatistics>>, TError = unknown>(
 params?: UserQuestionsStatisticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userQuestionsStatistics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserQuestionsStatisticsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get User Exams Statistics
 * @summary Your GET endpoint
 */
export const userExamsStatistics = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<UserExamsStatistics200Item[]>(
      {url: `/user/exams-stats`, method: 'GET', signal
    },
      );
    }
  

export const getUserExamsStatisticsQueryKey = () => {
    return [`/user/exams-stats`] as const;
    }

    
export const getUserExamsStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof userExamsStatistics>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userExamsStatistics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserExamsStatisticsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userExamsStatistics>>> = ({ signal }) => userExamsStatistics(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userExamsStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type UserExamsStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof userExamsStatistics>>>
export type UserExamsStatisticsQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useUserExamsStatistics = <TData = Awaited<ReturnType<typeof userExamsStatistics>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userExamsStatistics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserExamsStatisticsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get user subscription plans
 * @summary Your GET endpoint
 */
export const userSubscriptionPlans = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<UserSubscriptionPlans200Item[]>(
      {url: `/user/plans`, method: 'GET', signal
    },
      );
    }
  

export const getUserSubscriptionPlansQueryKey = () => {
    return [`/user/plans`] as const;
    }

    
export const getUserSubscriptionPlansQueryOptions = <TData = Awaited<ReturnType<typeof userSubscriptionPlans>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userSubscriptionPlans>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserSubscriptionPlansQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userSubscriptionPlans>>> = ({ signal }) => userSubscriptionPlans(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userSubscriptionPlans>>, TError, TData> & { queryKey: QueryKey }
}

export type UserSubscriptionPlansQueryResult = NonNullable<Awaited<ReturnType<typeof userSubscriptionPlans>>>
export type UserSubscriptionPlansQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useUserSubscriptionPlans = <TData = Awaited<ReturnType<typeof userSubscriptionPlans>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userSubscriptionPlans>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserSubscriptionPlansQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



