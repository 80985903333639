const getImagesUrlFromHTMLString = (str: string) => {
  let m
  const urls = [],
    rex = /src=["'](data:[^"']+)["']/g

  while ((m = rex.exec(str))) {
    urls.push(m[1])
  }

  return urls
}

export default getImagesUrlFromHTMLString
