import { FlashcardCollection } from '../api/model'

/**
 * Function to export a collection to a JSON string
 *
 * @param collection - Collection object to export
 * @returns - JSON string
 *
 * @throws - Error if the JSON string is invalid
 */
export const exportFlashcardCollection = (
  collection: FlashcardCollection,
): string => {
  try {
    return JSON.stringify(collection)
  } catch (e) {
    throw new FlashcardCollectionExportError('Invalid JSON string')
  }
}

/**
 * Function to import a collection from a JSON string
 *
 * @param json - JSON string to import
 * @returns - Imported FlashcardCollection objects
 *
 * @throws - Error if the JSON string is invalid
 */
export const importFlashcardCollection = (
  json: string,
): FlashcardCollection => {
  try {
    return JSON.parse(json) as FlashcardCollection
  } catch (e) {
    throw new FlashcardCollectionImportError('Invalid JSON string')
  }
}

class FlashcardCollectionImportError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'FlashcardCollectionImportError'
  }
}

class FlashcardCollectionExportError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'FlashcardCollectionExportError'
  }
}
