import clsx from 'clsx'
import { FC } from 'react'
import classes from './SearchBar.module.css'

interface SearchBarProps {
  placeholderText: string
  searchQuery?: string
  className?: string
  onSearch: (query: string) => void
}

const SearchBar: FC<SearchBarProps> = ({
  placeholderText,
  searchQuery = '',
  className,
  onSearch,
}) => {
  const handleSearch = (searchQuery: string) => {
    onSearch(searchQuery)
  }

  return (
    <input
      placeholder={placeholderText}
      value={searchQuery}
      onChange={(e) => handleSearch(e.target.value)}
      className={clsx(classes.searchBar, className)}
    />
  )
}

export default SearchBar
