import clsx from "clsx";
import { Steps } from "intro.js-react";
import { FC, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserInfo } from "../../api/user/user";
import { Typography } from "../../atoms";
import { NotepadCollectionsBoard } from "../../organisms";
import tutorialOptions from '../../utils/tutorialOptions';
import classes from "./NotepadCollections.module.css";

const NotepadCollections: FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [{ seeTutorialLater }] = useCookies(['seeTutorialLater'])

    const [isLoading, setIsLoading] = useState(true)
    const [stepsEnabled, setStepsEnabled] = useState(false)
    const [stepsStarted, setStepsStarted] = useState<boolean>(false)
    const [initialStep, setInitialStep] = useState(0)
    const [showTutorialOnlyForThisPage, setShowTutorialOnlyForThisPage] =
        useState(false)

    const steps = [
        {
            element: '.notepadCard',
            intro: t('notepad.tutorial.notepadCard'),
        },
    ]

    const { data: userInfo, refetch: refetchUserInfo } = useUserInfo({
        query: {
            onSuccess: (data) => { },
            cacheTime: 24 * 60 * 60 * 1000,
            staleTime: 24 * 60 * 60 * 1000,
        },
    })

    const onShowTutorialForThisPage = () => {
        setShowTutorialOnlyForThisPage(true)
        setStepsEnabled(true)
    }

    const userIsAdmin = useMemo(() => {
        return userInfo?.is_admin
    }, [userInfo])

    useEffect(() => {
        if (!seeTutorialLater) {
            refetchUserInfo()
        }
    }, [seeTutorialLater])

    useEffect(() => {
        if (userInfo && !seeTutorialLater)
            setStepsEnabled(!userInfo.finished_tutorial)
    }, [userInfo?.finished_tutorial])

    useEffect(() => {
        if (!stepsEnabled) {
            setShowTutorialOnlyForThisPage(false)
        }
    }, [stepsEnabled])

    return (
        <>
            <Card className="notepadCard">
                <Helmet>
                    <title>{`${t('common.appName')} - ${t('navigation.notepad')}`}</title>
                </Helmet>
                <Card.Body className="shadow-sm">
                    <Row className="mb-4 align-items-center">
                        <Col>
                            <Typography
                                variant="h3"
                                fontWeight="light"
                                className="mb-0 dark-text"
                            >
                                {t('navigation.notepadCollections')}
                            </Typography>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button
                                variant="link"
                                className={clsx(classes.showTutorialButton, 'text-dark')}
                                onClick={() => onShowTutorialForThisPage()}
                            >
                                {t('common.showTutorial')}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="ms-2">
                        <Col>
                            <NotepadCollectionsBoard
                                setIsLoading={setIsLoading}
                                initialEditMode={true}
                                userIsAdmin={userIsAdmin}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {!isLoading && (
                <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={initialStep}
                    onStart={() => {
                        setTimeout(function() {
                          setStepsStarted(true)
            
                        },500)
                      }}
                    onExit={() =>{
                        if (stepsEnabled && !showTutorialOnlyForThisPage && stepsStarted)
                            setTimeout(function(){
                                navigate('/study-calendar')
                      
                              },500)

                        if (showTutorialOnlyForThisPage) {
                            setStepsEnabled(false)
                        }
                    }}

                    options={{
                        ...tutorialOptions,
                        doneLabel: showTutorialOnlyForThisPage
                            ? t('common.done')
                            : t('common.nextPage'),
                    }}
                />
            )}
        </>
    );
}

export default NotepadCollections;
