// https://www.npmjs.com/package/react-arborist
// https://blog.logrocket.com/using-react-arborist-create-tree-components/

import clsx from 'clsx';
import { FC, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Tree } from "react-arborist";
import { Image as BsImage, Button, Col, Overlay, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ArrowBarLeft, ArrowBarRight, ArrowLeftCircleFill, ArrowRightCircleFill, ArrowsAngleExpand, CaretDownFill, CaretRightFill, Eye, FileText, FolderFill, Grid3x3GapFill, Lightning, ListTask, ThreeDotsVertical } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useCategoriesImages } from '../../api/exams/exams';
import { CategoryImage, Image } from '../../api/model';
import { Loading, SearchBar, TableCell, Typography } from '../../atoms';
import { Modal, Table } from '../../molecules';
import FlashcardCollectionsList from '../FlashcardCollectionsList/FlashcardCollectionsList';
import NotepadCollectionsBoard from '../NotepadCollectionsBoard/NotepadCollectionsBoard';
import './TablesBoard.css';
import classes from './TablesBoard.module.css';

enum Views {
  LIST_VIEW,
  GRID_VIEW
}

interface TopicTreeData {
  id: string
  name: string
  images: Image[]
  children: TopicTreeData[]
}

/**
 * This function converts the topics data into a tree data structure
 * that can be used by the Tree component
 * Copies the subtopics into the children property
 * 
 * @param topics - An array of topics
 * @returns The tree data structure
 */
const fromTopicsDataToTopicTreeDataConverter = (topics: CategoryImage[]): TopicTreeData[] => {
  return topics.map((topic) => {
    const children = topic.sub_categories
      ? fromTopicsDataToTopicTreeDataConverter(topic.sub_categories)
      : []

    return {
      id: topic.id!,
      name: topic.name!,
      images: topic.images,
      children: children,
    }
  })
}

/**
 * This function converts the tree data structure into a topics data structure.
 * It copies the children into the subtopics property
 * 
 * @param topics - The tree data structure
 * @returns An array of topics
 */
const fromTopicTreeDataToTopicsDataConverter = (topics: TopicTreeData[]): CategoryImage[] => {
  return topics.map((topic) => {
    const subcategories = topic.children
      ? fromTopicTreeDataToTopicsDataConverter(topic.children)
      : []

    return {
      id: topic.id,
      title: topic.name,
      images: topic.images,
      sub_categories: subcategories,
    }
  })
}

interface TopicsContextProps {
  searchQuery: string
  focusTopic: (topicId: string) => void
}

const TopicsContext = createContext<TopicsContextProps>({
  searchQuery: '',
  focusTopic: () => { },
})

const TopicNode: FC = ({ node, style, dragHandle, tree }: any) => {
  const {
    focusTopic
  } = useContext(TopicsContext)

  const hasChildren = useMemo(() => node.children?.length > 0, [node.children])

  /**
   * This function focuses the topic in the context
   * This is used to show the content of the topic in the editor
   */
  useEffect(() => {
    if (node.state.isSelected) {
      focusTopic(node.data.id)
    }
  }, [node.state.isSelected])

  return (
    <div style={style} {...dragHandle} title={node.data.name}>
      <div
        className="node-content"
      >
        <div className='d-flex align-items-center'>
          <span className='arrow' onClick={() => node.isInternal && node.toggle()}>
            {
              hasChildren ?
                node.isOpen ?
                  <CaretDownFill size={12} /> : <CaretRightFill size={12} />
                : null
            }
          </span>
          <span className='arrow'>
            <FolderFill size={16} color={node.state.isSelected ? 'green' : 'dark'} />
          </span>
        </div>
        <span className="text-truncate" >
          <span>{node.data.name}</span>
        </span>
      </div>
    </div >
  );
}

interface TopicTreeProps {
  data: TopicTreeData[],
  treeRef: React.RefObject<any>
}

const TopicTree: FC<TopicTreeProps> = ({
  data,
  treeRef
}) => {
  const findNodeById = (id: string | null, topics: TopicTreeData[]): TopicTreeData | undefined => {
    if (!id) return
    for (const topic of topics) {
      if (topic.id === id) {
        return topic
      } else if (topic.children && topic.children.length > 0) {
        const foundSubtopic = findNodeById(id, topic.children)

        if (foundSubtopic) {
          return foundSubtopic
        }
      }
    }
    return
  }

  useEffect(() => {
    treeRef.current?.closeAll()
  }, [data])

  return (
    <Tree
      ref={treeRef}
      data={data}
      width={'100%'}
      height={window.innerHeight - 200}
      className={classes.tree}
      indent={24}
      rowHeight={32}
      onMove={() => { }}
    >
      {TopicNode}
    </Tree >
  );
};

const tableHeadings = [
  {
    slug: 'image-heading',
    content: 'common.image',
    className: 'w-25 text-center',
  },
  {
    slug: 'name-heading',
    content: 'common.name',
    className: 'w-auto text-center',
  },
  {
    slug: 'actions-heading',
    content: 'common.actions',
    className: 'w-25 text-center',
  }
]

const TablesBoard: FC = () => {
  const { t } = useTranslation()

  const treeRef = useRef(null);
  const buttonRefs = useRef<HTMLButtonElement[]>([]);

  const [currentView, setCurrentView] = useState<Views>(Views.LIST_VIEW)

  const [selectedTopic, setSelectedTopic] = useState<TopicTreeData | undefined>(undefined)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [showImageMenuTooltips, setShowImageMenuTooltips] = useState<boolean[]>([]);
  const [imageHoverState, setImageHoverState] = useState<boolean[]>([])

  // Are used to keep the original topics data. It is not affected by the search query
  const [topics, setTopics] = useState<TopicTreeData[]>([])
  // Are used to keep the topics data that is affected by the search query
  const [filteredTopics, setFilteredTopics] = useState<TopicTreeData[]>([])
  const [selectedTopicTitle, setSelectedTopicTitle] = useState<
    string | undefined
  >(undefined)

  const [selectedImage, setSelectedImage] = useState<Image | undefined>(undefined)

  const [highlightedText, setHighlightedText] = useState<string | undefined>(undefined)

  const [topicsMenuCollapsed, setTopicsMenuCollapsed] = useState<boolean>(false)

  const [showImageModal, setShowImageModal] = useState<boolean>(false)
  const [showFlashcardsModal, setShowFlashcardsModal] = useState<boolean>(false)
  const [showNotepadModal, setShowNotepadModal] = useState<boolean>(false)

  const allImagesFromCategory = useMemo(() => {
    if (selectedTopic) {
      const images: Image[] = []

      // Don't add repeated images
      const searchImages = (topic: TopicTreeData) => {
        if (topic.images) {
          topic.images.forEach((image) => {
            let index = images.findIndex((item) => item.name === image.name)
            if (index === -1)
              images.push(image)
          })
        }

        topic.children?.forEach((subtopic) => searchImages(subtopic))
      }

      searchImages(selectedTopic)

      // Sort images
      images.sort((a, b) => a.name.localeCompare(b.name))

      return images
    }

    return []
  }, [selectedTopic])

  const filteredImages = useMemo(() => {
    if (searchQuery === '') {
      return allImagesFromCategory
    }

    return allImagesFromCategory.filter((image) =>
      image.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [searchQuery, allImagesFromCategory])

  const selectedImageIndex = useMemo(() => {
    return allImagesFromCategory.findIndex((image) => image.id === selectedImage?.id)
  }, [selectedImage])

  /**
   * This function sets the topics data. 
   * It sets the topics and the filtered topics to the same data
   * 
   * @param data - The topics data
   */
  const setTopicsData = (data: TopicTreeData[]) => {
    setTopics(data)
    setFilteredTopics(data)
  }

  const handleSearch = (query: string) => {
    setSearchQuery(query)
    const filteredTopics = filterTopics(topics, query)
    setFilteredTopics(filteredTopics)
  }

  const filterTopics = (topics: TopicTreeData[], query: string) => {
    if (query === '') {
      return topics
    }

    const filteredTopics: TopicTreeData[] = []

    const searchTopics = (topics: TopicTreeData[], query: string) => {
      topics.forEach((topic) => {
        if (topic.name.toLowerCase().includes(query.toLowerCase())) {
          filteredTopics.push(topic)
        }

        if (topic.children && topic.children.length > 0) {
          searchTopics(topic.children, query)
        }
      })
    }

    searchTopics(topics, query)

    return filteredTopics
  }

  const searchTopicById = (
    topics: TopicTreeData[] | undefined,
    id: string,
  ): TopicTreeData | undefined => {
    if (!topics) return

    for (const topic of topics) {
      if (topic.id === id) {
        return topic
      } else if (topic.children && topic.children.length > 0) {
        const foundSubtopic: TopicTreeData | undefined = searchTopicById(
          topic.children,
          id,
        )
        if (foundSubtopic) {
          return foundSubtopic
        }
      }
    }
    return
  }

  const sortTopicsByTitle = (topics: TopicTreeData[], reverse?: boolean): TopicTreeData[] => {
    let reverseFactor = 1

    if (reverse) {
      reverseFactor = -1
    }

    return topics
      .map((topic) => ({
        ...topic,
        children: sortTopicsByTitle(topic.children ?? []),
      }))
      .sort((a, b) => {
        if (a.name! < b.name!) {
          return -1 * reverseFactor
        }
        if (a.name! > b.name!) {
          return 1 * reverseFactor
        }
        return 0
      })
  }

  const handleImageSelect = (imageIdx: number) => {
    const image = allImagesFromCategory[imageIdx]

    setSelectedImage(image)
    setShowImageMenuTooltips(new Array(allImagesFromCategory.length).fill(false))
    setShowImageModal(true)
  }

  const handleTooltipButtonClick = (imageIdx?: number) => {
    const newTooltipsState = showImageMenuTooltips.map((tooltip, index) => {
      return index === imageIdx ? !tooltip : false;
    });

    setShowImageMenuTooltips(newTooltipsState);
  };

  const handleAddToFlashcard = ({ url, name }: Image) => {
    setShowImageModal(false)
    setShowFlashcardsModal(true)

    setHighlightedText(`<img src="${url}" alt="${name}" width={150} height={150} />`)

    setShowImageMenuTooltips(new Array(allImagesFromCategory.length).fill(false))
  }

  const handleAddToNotepad = ({ url, name }: Image) => {
    setShowImageModal(false)
    setShowNotepadModal(true)

    const highlightedText = `<img src="${url}" alt="${name}" width={150} height={150} />`

    sessionStorage.setItem("highlightedText", highlightedText)

    setShowImageMenuTooltips(new Array(allImagesFromCategory.length).fill(false))
  }

  const { data: categoryImages, isLoading: isLoadingImages } = useCategoriesImages({
    query: {
      cacheTime: 24 * 60 * 60 * 1000,
      staleTime: 24 * 60 * 60 * 1000,
    }
  })

  useEffect(() => {
    if (selectedTopic) {
      setSelectedTopicTitle(selectedTopic.name)
    }
  }, [selectedTopic])

  useEffect(() => {
    // Remove leaf topics that do not have images
    const removeEmptyLeafTopics = (topics: TopicTreeData[]): TopicTreeData[] => {
      return topics
        .map((topic) => ({
          ...topic,
          children: removeEmptyLeafTopics(topic.children),
        }))
        .filter((topic) => topic.images.length > 0 || topic.children.length > 0)
    }

    const topicsTreeData = fromTopicsDataToTopicTreeDataConverter(categoryImages ?? [])

    const removeEmptyLeafTopicsData = removeEmptyLeafTopics(topicsTreeData)

    setTopicsData(removeEmptyLeafTopicsData)

  }, [categoryImages])

  useEffect(() => {
    if (categoryImages && !selectedTopic) {
      const selectedTopic = searchTopicById(topics, categoryImages[0].id)

      setSelectedTopic(selectedTopic)
    }
  }), [categoryImages]

  const TopicsContextValue: TopicsContextProps = {
    searchQuery,
    focusTopic: (topicId) => {
      const selectedTopic = searchTopicById(topics, topicId)
      setSelectedTopic(selectedTopic)
    }
  }

  useEffect(() => {
    const tooltips = new Array(allImagesFromCategory.length).fill(false)

    setShowImageMenuTooltips(tooltips)
    setImageHoverState(tooltips)
  }, [allImagesFromCategory])

  return (
    <>
      {
        isLoadingImages ? <Loading />
          :
          (
            <>
              <Row>
                <Col className={topicsMenuCollapsed ? classes.collapsed : classes.expanded} lg={4}>
                  {
                    !topicsMenuCollapsed && (
                      <>
                        <Row className="mb-4 align-items-center">
                          <Col className="d-flex align-items-center justify-content-between"
                          >
                            <Col>
                              <SearchBar
                                searchQuery={searchQuery}
                                onSearch={handleSearch}
                                className={classes.searchBar}
                                placeholderText={t('notepad.searchBarPlaceholder')}
                              />
                            </Col>
                          </Col>
                        </Row>
                        {filteredTopics.length > 0 && (
                          <TopicsContext.Provider value={TopicsContextValue}>
                            <TopicTree data={filteredTopics} treeRef={treeRef} />
                          </TopicsContext.Provider>
                        )}
                      </>
                    )
                  }
                </Col>
                {
                  selectedTopic && (
                    <Col className='col-auto d-md-none d-lg-block'>
                      <Button
                        variant="white"
                        className="text-dark p-0"
                      >
                        {
                          topicsMenuCollapsed ?
                            <ArrowBarRight size={12}
                              onClick={() => setTopicsMenuCollapsed(false)}
                              title={t('notepad.expandTopics')} /> :
                            <ArrowBarLeft size={12}
                              onClick={() => setTopicsMenuCollapsed(true)}
                              title={t('notepad.collapseTopics')} />
                        }
                      </Button>
                    </Col>
                  )
                }
                <Col className={classes.textEditorWrapper}>
                  {selectedTopic && (
                    <>
                      <Row
                        className="mb-2 align-items-center justify-content-between"
                        style={{ height: 40 }}
                      >

                        <Col>
                          {
                            searchQuery === '' &&
                            <Typography variant="h3" className="mb-0">
                              {selectedTopicTitle}
                            </Typography>
                          }
                        </Col>
                        <Col className='col-auto'>
                          <Button
                            variant="white"
                            className="text-dark p-0 me-2"
                            onClick={() => setCurrentView(Views.LIST_VIEW)}
                          >
                            <ListTask size={22} title={t('notepad.listView')} />
                          </Button>
                          <Button
                            variant="white"
                            className="text-dark p-0"
                            onClick={() => setCurrentView(Views.GRID_VIEW)}
                          >
                            <Grid3x3GapFill size={18} title={t('notepad.gridView')} color='grey' />
                          </Button>
                        </Col>
                      </Row>
                      <Row className={clsx(classes.tablesListContainer, "mb-2 overflow-auto")} style={{ height: window.innerHeight - 200 }}>
                        {currentView === Views.LIST_VIEW ? (
                          <Table headings={tableHeadings} >
                            {filteredImages.map((image, idx) => (
                              <tr key={image.id} className={classes.tableRow}>
                                <TableCell className='text-center'>
                                  <OverlayTrigger
                                    key="view-image-top"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view-notepad`}>
                                        {t('common.preview')}
                                      </Tooltip>
                                    }
                                  >
                                    <BsImage
                                      src={image.url}
                                      alt={image.name}
                                      width={25}
                                      height={25}
                                      className={clsx(classes.hover)}
                                      onClick={() => handleImageSelect(idx)}
                                      loading='lazy'
                                    />
                                  </OverlayTrigger>
                                </TableCell>
                                <TableCell className='text-center'>
                                  <OverlayTrigger
                                    key="view-title-top"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view-notepad`}>
                                        {t('common.preview')}
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      className={clsx(classes.hover)}
                                      onClick={() => handleImageSelect(idx)}
                                    >{image.name}
                                    </span>
                                  </OverlayTrigger>
                                </TableCell>
                                <TableCell className='text-center'>
                                  <Button
                                    variant="none"
                                    ref={(ref: HTMLButtonElement) => (buttonRefs.current[idx] = ref)}
                                    onClick={() => handleTooltipButtonClick(idx)}
                                  >
                                    <ThreeDotsVertical />
                                  </Button>

                                  <Overlay
                                    show={showImageMenuTooltips[idx]}
                                    target={buttonRefs.current[idx]}
                                    placement="top"
                                    containerPadding={20}
                                    onHide={() => setShowImageMenuTooltips((prev) =>
                                      prev.map((tooltip, index) => (index === idx ? false : tooltip))
                                    )}
                                    rootClose={true}
                                  >
                                    <Tooltip
                                      id={`tooltip-${idx}`}
                                      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}

                                      className={"custom-tooltip d-flex flex-column p-2 text-dark"}
                                    >
                                      <>
                                        <div
                                          className={clsx(classes.tooltipItem, "d-flex align-items-center")}
                                          onClick={() => handleImageSelect(idx)}
                                        >
                                          <Eye className={classes.icon} />
                                          {t('common.preview')}
                                        </div>
                                        <div
                                          className={clsx(classes.tooltipItem, "d-flex align-items-center")}
                                          onClick={() => {
                                            setSelectedImage(image)
                                            handleAddToFlashcard(image)
                                          }}
                                        >
                                          <Lightning className={classes.icon} />
                                          <div dangerouslySetInnerHTML={{ __html: t('tables.addToFlashcard') }} />
                                        </div>
                                        <div
                                          className={clsx(classes.tooltipItem, "d-flex align-items-center")}
                                          onClick={() => {
                                            setSelectedImage(image)
                                            handleAddToNotepad(image)
                                          }}
                                        >
                                          <FileText className={classes.icon} />
                                          {t('tables.addToNotepad')}
                                        </div>
                                      </>
                                    </Tooltip>
                                  </Overlay>
                                </TableCell>
                              </tr>
                            ))}
                          </Table>
                        ) :
                          (
                            <>
                              {
                                filteredImages.map((image, idx) => (
                                  <Col xs={6} md={4} lg={3} key={image.id} className='text-center mt-3'>
                                    <div onMouseOver={() => {
                                      const newImageHoverState = new Array(filteredImages.length).fill(false)

                                      newImageHoverState[idx] = true

                                      setImageHoverState(newImageHoverState)
                                    }}
                                      onMouseLeave={() => {
                                        setImageHoverState(new Array(filteredImages.length).fill(false))
                                      }}

                                      style={{
                                        position: 'relative',
                                        display: 'flex',
                                        cursor: 'pointer'
                                      }}

                                      className='flex align-items-center justify-content-center'
                                    >
                                      {/** Image in the center with a dark overlay when hovered */}
                                      <BsImage
                                        src={image.url}
                                        alt={image.name}
                                        width={150}
                                        height={150}
                                        loading='lazy' />
                                      {imageHoverState[idx] && (
                                        <div style={{
                                          position: "absolute",
                                          width: '100%',
                                          height: '100%',
                                        }}
                                          className={clsx(classes.imageGridContainer)}
                                        >
                                          <div
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                              position: 'relative'
                                            }}
                                          >
                                            <div
                                              style={{
                                                height: '100%',
                                                display: 'flex'
                                              }}
                                              className='flex justify-content-center align-items-center'
                                            >
                                              <OverlayTrigger
                                                key="view-notepad-top"
                                                placement="top"
                                                overlay={
                                                  <Tooltip id={`tooltip-view-notepad`}>
                                                    {t('common.preview')}
                                                  </Tooltip>
                                                }
                                              >
                                                <Button
                                                  variant="none"
                                                  onClick={() => { handleImageSelect(idx) }}
                                                  style={{
                                                    top: '50%',
                                                    color: 'white',
                                                    border: '#bebebe 1px solid',
                                                    borderRadius: '50%'
                                                  }}
                                                >
                                                  <ArrowsAngleExpand />
                                                </Button>
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='mt-2' style={{ fontSize: 12 }}>
                                      <span>{image.name}</span>
                                    </div>
                                  </Col>
                                ))
                              }
                            </>
                          )
                        }
                      </Row>
                    </>
                  )
                  }
                </Col>
              </Row >
              <Modal
                show={showImageModal}
                title={selectedImage?.name ?? ''}
                closeButton
                size='xl'
                style={{
                  maxHeight: '90vh',
                }}
                onHide={() => {
                  setShowImageModal(false)
                  setSelectedImage(undefined)
                }}
                body={
                  <>
                    < div className='d-flex justify-content-between align-items-center'>
                      <div className='col-sm-1 text-center'>
                        {
                          // If the selected image is not the first image in the array, show the left arrow button
                          // Otherwise, show an empty div
                          selectedImageIndex > 0 ?
                            <Button
                              variant='none'
                              onClick={() => {
                                if (selectedImageIndex > 0) {
                                  setSelectedImage(allImagesFromCategory[selectedImageIndex - 1])
                                }
                              }}
                            >
                              <ArrowLeftCircleFill size={24} />
                            </Button> :
                            <div>
                              <Button
                                variant='none'
                              />
                            </div>
                        }
                      </div>
                      <div className='col-sm-10' style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                        <BsImage
                          src={selectedImage?.url}
                          alt={selectedImage?.name}
                          fluid
                          loading='lazy' />
                      </div>
                      <div className='col-sm-1 text-center'>
                        {
                          // If the selected image is not the last image in the array, show the right arrow button
                          // Otherwise, show an empty div
                          selectedImageIndex < allImagesFromCategory.length - 1 ?
                            <Button
                              variant='none'
                              onClick={() => {
                                if (selectedImageIndex < allImagesFromCategory.length - 1) {
                                  setSelectedImage(allImagesFromCategory[selectedImageIndex + 1])
                                }
                              }}
                            >
                              <ArrowRightCircleFill size={24} />
                            </Button> :
                            <div>
                              <Button
                                variant='none'
                              />
                            </div>
                        }
                      </div>
                    </div>
                  </>
                }
                footer={
                  <div className='text-center w-100'>
                    <Button
                      variant='dark'
                      onClick={() => handleAddToFlashcard(selectedImage!)}
                    >
                      <div className='d-flex align-items-center'>
                        <Lightning size={20} className='me-2' />
                        <div dangerouslySetInnerHTML={{ __html: t('tables.addToFlashcard') }} />
                      </div>
                    </Button>
                    <Button
                      variant='dark'
                      className='ms-2'
                      onClick={() => handleAddToNotepad(selectedImage!)}
                    >
                      <div className='d-flex align-items-center'>
                        <FileText size={20} className='me-2' />
                        {t('tables.addToNotepad')}
                      </div>
                    </Button>
                  </div >
                }
              />
              <Modal
                show={showFlashcardsModal}
                size="xl"
                onHide={() => setShowFlashcardsModal(false)}
                title={t('exam.flashcards.addToExistingFlashcard')}
                closeButton
                body={
                  <FlashcardCollectionsList
                    highlightedText={highlightedText}
                    onPastedText={() => {
                      setShowFlashcardsModal(false)
                    }}
                    addMode={true}
                  />
                }
              />
              <Modal
                show={showNotepadModal}
                size="xl"
                onHide={() => {
                  setShowNotepadModal(false)
                }}
                closeButton
                title={t('navigation.notepad')}
                body={
                  <NotepadCollectionsBoard
                    addMode={true}
                  />
                }
              />
            </>
          )
      }
    </>
  )
}

export default TablesBoard
