import { FC } from 'react';

import { Loading } from '../../atoms';
import classes from './FullscreenLoader.module.css';
import clsx from 'clsx';

const FullscreenLoader: FC = () => {
  return (
    <div className={clsx('bg-dark', classes.loading)}>
      <Loading />
    </div>
  );
}

export default FullscreenLoader
