import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Tag } from '../../api/model'
import filterIcon from '../../assets/images/filter_icon.svg'
import { FlashcardsCheckboxTree } from '../'
import classes from './FlashcardsFilterDropdown.module.css'

interface FlashcardsFilterDropdownProps {
  tags: Tag[]
  onCheckedTagsChange?: (checkedTags: string[]) => void
  onExpandedTagsChange?: (expandedTags: string[]) => void
  selectFavorites: boolean
  setSelectFavorites: (selectFavorites: boolean) => void
}

const FlashcardsFilterDropdown: FC<FlashcardsFilterDropdownProps> = ({
  tags,
  onCheckedTagsChange = () => { },
  onExpandedTagsChange = () => { },
  selectFavorites,
  setSelectFavorites,
}) => {
  const { t } = useTranslation()

  const noDuplicatedTags = useMemo(() => {
    const noDuplicatedTags: Tag[] = []
    tags.forEach((tag) => {
      if (
        !noDuplicatedTags.find(
          (noDuplicatedTag) => noDuplicatedTag.title === tag.title,
        )
      ) {
        noDuplicatedTags.push(tag)
      }
    })
    return noDuplicatedTags
  }, [tags])

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle variant="default" className={classes.filterDropdown}>
        <img src={filterIcon} width={20} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={clsx(classes.filterDropdownMenu, 'p-2')}>
        <Dropdown.Header>{t('common.tags')}</Dropdown.Header>
        <div className={clsx(classes.checkboxtreeContainer, 'ps-3')}>
          <FlashcardsCheckboxTree
            tags={noDuplicatedTags}
            onCheckedTagsChange={onCheckedTagsChange}
            onExpandedTagsChange={onExpandedTagsChange}
          />
        </div>
        <Dropdown.Header>{t('common.filters')}</Dropdown.Header>
        <div className="ps-3">
          <Form.Switch
            type="checkbox"
            label={t('flashcards.favorites')}
            defaultChecked={selectFavorites}
            className={classes.favoritesSwitch}
            onChange={() => setSelectFavorites(!selectFavorites)}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default FlashcardsFilterDropdown
