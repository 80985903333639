import { lazy } from 'react'
import Card from 'react-bootstrap/Card'
import { Navigate, RouteObject } from 'react-router-dom'
import NotepadCollections from '../pages/NotepadCollections/NotepadCollections'

const Exam = lazy(() => import('../pages').then((module) => ({ default: module.Exam })))
const Account = lazy(() => import('../pages').then((module) => ({ default: module.Account })))
const CreateExam = lazy(() => import('../pages').then((module) => ({ default: module.CreateExam })))
const Flashcards = lazy(() => import('../pages').then((module) => ({ default: module.Flashcards })))
const ForumLogin = lazy(() => import('../pages').then((module) => ({ default: module.ForumLogin })))
const Tables = lazy(
  () => import('../pages').then((module) => ({ default: module.Tables })
  ))
const History = lazy(() => import('../pages').then((module) => ({ default: module.History })))
const Login = lazy(() => import('../pages').then((module) => ({ default: module.Login })))
const NewPassword = lazy(() => import('../pages').then((module) => ({ default: module.NewPassword })))
const PremadeExam = lazy(() => import('../pages').then((module) => ({ default: module.PremadeExam })))
const ReferenceValues = lazy(
  () => import('../pages').then((module) => ({ default: module.ReferenceValues })),
)
const Register = lazy(() => import('../pages').then((module) => ({ default: module.Register })))
const ResetPassword = lazy(() => import('../pages').then((module) => ({ default: module.ResetPassword })))
const Statistics = lazy(() => import('../pages').then((module) => ({ default: module.Statistics })))
const StudyCalendar = lazy(() => import('../pages').then((module) => ({ default: module.StudyCalendar })))
const Summary = lazy(() => import('../pages').then((module) => ({ default: module.Summary })))

const Authenticated = lazy(
  () => import('../templates').then((module) => ({ default: module.Authenticated })),
)
const Authentication = lazy(
  () => import('../templates').then((module) => ({ default: module.Authentication })),
)

export const Public: RouteObject[] = [
  {
    path: '/register',
    element: (
      <Authentication>
        <Register />
      </Authentication>
    ),
  },
  {
    path: '/reset-password',
    element: (
      <Authentication>
        <ResetPassword />
      </Authentication>
    ),
  },
  {
    path: '/login',
    element: (
      <Authentication>
        <Login />
      </Authentication>
    ),
  },
  {
    path: '/forum/login',
    element: (
      <Authentication>
        <ForumLogin />
      </Authentication>
    ),
  },
  {
    path: '/new-password',
    element: (
      <Authentication>
        <NewPassword />
      </Authentication>
    ),
  },
  {
    path: '*',
    element: <Navigate to={'/login'} />,
  },
]

export const Private: RouteObject[] = [
  {
    path: '/dashboard',
    element: (
      <Authenticated>
        <Card>
          <Card.Body className="shadow-sm">dashboard</Card.Body>
        </Card>
      </Authenticated>
    ),
  },
  {
    path: '/create-exam',
    element: (
      <Authenticated>
        <CreateExam />
      </Authenticated>
    ),
  },
  {
    path: '/simulate-exam',
    element: (
      <Authenticated>
        <PremadeExam />
      </Authenticated>
    ),
  },
  {
    path: '/history',
    element: (
      <Authenticated>
        <History />
      </Authenticated>
    ),
  },
  {
    path: '/statistics',
    element: (
      <Authenticated>
        <Statistics />
      </Authenticated>
    ),
  },
  {
    path: '/plans',
    element: (
      <Authenticated>
        <Account />
      </Authenticated>
    ),
  },
  {
    path: '/exam/:examId',
    element: <Exam />,
  },
  {
    path: '/exam/:examId/:questionId',
    element: <Exam />,
  },
  {
    path: '/exam/:examId/summary',
    element: (
      <Authenticated>
        <Summary />
      </Authenticated>
    ),
  },
  {
    path: '/notepad',
    element: (
      <Authenticated>
        <NotepadCollections />
      </Authenticated>
    ),
  },
  {
    path: '/flashcards',
    element: (
      <Authenticated>
        <Flashcards />
      </Authenticated>
    ),
  },
  {
    path: '/tables',
    element: (
      <Authenticated>
        <Tables />
      </Authenticated>
    ),
  },
  {
    path: '/study-calendar',
    element: (
      <Authenticated>
        <StudyCalendar />
      </Authenticated>
    ),
  },
  {
    path: '/forum/login',
    element: <ForumLogin />,
  },
  {
    path: '/reference-values',
    element: <ReferenceValues />,
  },
  {
    path: '*',
    element: <Navigate to={'/create-exam'} />,
  },
]
