import clsx from 'clsx'
import { ReactNode, FC } from 'react'
import classes from './TableCell.module.css'

export interface TableCellProps {
  children?: ReactNode
  borderless?: boolean
  className?: string
}

const TableCell: FC<TableCellProps> = ({
  children,
  borderless = false,
  className,
}) => {
  return (
    <td
      className={clsx(classes.container, {
        [classes.borderless]: borderless,
      }, className)}
    >
      {children}
    </td>
  )
}

export default TableCell
