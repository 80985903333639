import { FC, useState } from 'react'
import { CaretDown, CaretRight } from 'react-bootstrap-icons'
import CheckboxTree, { CheckboxProps } from 'react-checkbox-tree'
import { TFunction, useTranslation } from 'react-i18next'
import { Tag } from '../../api/model'
import './FlashcardsCheckboxTree.css'

interface FlashcardsCheckboxTreeProps {
  tags: Tag[]
  onCheckedTagsChange?: (checkedTags: string[]) => void
  onExpandedTagsChange?: (expandedTags: string[]) => void
}

const FlashcardsCheckboxTree: FC<FlashcardsCheckboxTreeProps> = ({
  tags,
  onCheckedTagsChange = () => { },
  onExpandedTagsChange = () => { },
}) => {
  const { t } = useTranslation()

  const [checkedTags, setCheckedTags] = useState<string[]>([])
  const [expandedTags, setExpandedTags] = useState<string[]>(['ALL'])

  const getTagNodes = (
    t: TFunction<'translation', undefined>,
    tags: Tag[],
  ): CheckboxProps['nodes'] => [
      {
        value: 'ALL',
        label: (
          <>
            <span>{t(`flashcards.allTags`)}</span>
          </>
        ),
        children: buildTags(tags),
      },
    ]

  const buildTags = (tags: Tag[]): CheckboxProps['nodes'] =>
    tags.map(({ id, title }) => ({
      value: id!,
      label: (
        <>
          <span>{title}</span>
        </>
      ),
    }))

  return (
    <CheckboxTree
      nodes={getTagNodes(t, tags)}
      checked={checkedTags}
      onCheck={(aChecked) => {
        setCheckedTags(aChecked)
        onCheckedTagsChange(aChecked)
      }}
      onExpand={(aExpanded) => {
        setExpandedTags(aExpanded)
        onExpandedTagsChange(aExpanded)
      }}
      expanded={expandedTags}
      nativeCheckboxes={true}
      icons={{
        expandClose: <CaretRight />,
        expandOpen: <CaretDown />,
      }}
    />
  )
}

export default FlashcardsCheckboxTree
