import { FC } from 'react'
import Alert from 'react-bootstrap/Alert'
import { animated, useTransition } from 'react-spring'
import { useNotifications } from '../../context/NotificationContext'
import classes from './Notifications.module.css'

const Notifications: FC = () => {
  const { notifications, clearNotification } = useNotifications()

  const transitions = useTransition(notifications, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <div className={`${classes.container}`}>
      {transitions(({ opacity }, item) => (
        <animated.div
          style={{
            opacity: opacity,
          }}
        >
          <Alert
            variant={item.variant}
            onClose={() => clearNotification(item.id)}
            dismissible
          >
            <Alert.Heading>{item.title}</Alert.Heading>
            <p className="fs-6">{item.message}</p>
          </Alert>
        </animated.div>
      ))}
    </div>
  )
}

export default Notifications
