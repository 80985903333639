import { Collection } from '../api/model'

/**
 * Function to export a collection to a JSON string
 *
 * @param collection - Collection object to export
 * @returns - JSON string
 *
 * @throws - Error if the JSON string is invalid
 */
export const exportNotepadCollection = (
  collection: Collection,
): string => {
  try {
    return JSON.stringify(collection)
  } catch (e) {
    throw new NotepadCollectionExportError('Invalid JSON string')
  }
}

/**
 * Function to import a collection from a JSON string
 *
 * @param json - JSON string to import
 * @returns - Imported Collection objects
 *
 * @throws - Error if the JSON string is invalid
 */
export const importNotepadCollection = (
  json: string,
): Collection => {
  try {
    return JSON.parse(json) as Collection
  } catch (e) {
    throw new NotepadCollectionImportError('Invalid JSON string')
  }
}

class NotepadCollectionImportError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'NotepadCollectionImportError'
  }
}

class NotepadCollectionExportError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'NotepadCollectionExportError'
  }
}
