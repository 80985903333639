import { forwardRef } from 'react'
import { IconProps } from '../../molecules/NavItem/NavItem'

const Stats = forwardRef<any, IconProps>(({ size, color, ...rest }, ref) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    ref={ref}
    fill={color}
    viewBox="0 0 32 34.909"
  >
    <g
      id="Icon_ionic-md-stats"
      data-name="Icon ionic-md-stats"
      transform="translate(-5.625 -4.5)"
    >
      <path
        id="Path_3226"
        data-name="Path 3226"
        d="M12.375,4.5h4.5V39.409h-4.5Z"
        transform="translate(2.417)"
        fill={color}
      />
      <path
        id="Path_3227"
        data-name="Path 3227"
        d="M5.625,23.625h4.5V31.5h-4.5Z"
        transform="translate(0 7.909)"
        fill={color}
      />
      <path
        id="Path_3228"
        data-name="Path 3228"
        d="M19.125,19.125h4.5V31.5h-4.5Z"
        transform="translate(4.833 7.909)"
        fill={color}
      />
      <path
        id="Path_3229"
        data-name="Path 3229"
        d="M25.875,12.375h4.5V31.5h-4.5Z"
        transform="translate(7.25 7.909)"
        fill={color}
      />
    </g>
  </svg>
))

export default Stats
