import clsx from 'clsx'
import * as material from 'material-colors'
import { FC, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useDeckUpdate,
  useDeleteDeck,
  useTagsList,
  useUpdateDeckLastStudied
} from '../../api/flashcards/flashcards'
import { Deck, Flashcard, Tag } from '../../api/model'
import { TableCell, Typography } from '../../atoms'
import classes from './FlashcardsStudyBoard.module.css'
// From https://casesandberg.github.io/react-color/
import { PlayBtn } from 'react-bootstrap-icons'
import { CirclePicker } from 'react-color'
import SearchBar from '../../atoms/SearchBar/SearchBar'
import { useNotifications } from '../../context/NotificationContext'
import { Table } from '../../molecules'
import ColorPicker from '../../molecules/ColorPicker/ColorPicker'
import EditDeckModal from '../../molecules/EditDeckModal/EditDeckModal'
import FlashcardStudyBoard from '../../molecules/FlashcardStudyBoard/FlashcardStudyBoard'
import FlashcardsFilterDropdown from '../../molecules/FlashcardsFilterDropdown/FlashcardsFilterDropdown'
import getTimeDifference from '../../utils/getTimeDifference'
import FlashcardsBoard from '../FlashcardsBoard/FlashcardsBoard'
import './FlashcardsStudyBoard.css'

const tableHeadings = [
  {
    slug: 'cards-collection-heading',
    content: 'flashcards.study.cardsCollection',
    className: '',
  },
  {
    slug: 'choose-deck-heading',
    content: 'flashcards.study.chooseDeck',
    className: '',
  },
  {
    slug: 'cards-number-heading',
    content: 'flashcards.study.numberOfCards',
    className: '',
  },
  {
    slug: 'last-used-heading',
    content: 'flashcards.study.lastUsed',
    className: '',
  },
  {
    slug: 'action-heading',
    content: 'flashcards.study.study',
    className: '',
  },
]

type FormValues = {
  deckName: string
}

export interface StudyTableDeck extends Deck {
  collection_name: string;
}

interface FlashcardsStudyBoardProps {
  decks: StudyTableDeck[]
  onCloseFlashcard?: () => void
}

const FlashcardsStudyBoard: FC<FlashcardsStudyBoardProps> = ({
  decks,
  onCloseFlashcard = () => { },
}) => {
  const { t } = useTranslation()
  const { setNotification } = useNotifications()

  const { handleSubmit } = useForm()

  const [flashcardsDecks, setFlashcardsDecks] = useState<StudyTableDeck[]>([])
  const [showFlashcardBoardModal, setShowFlashcardBoardModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deckIdxSelected, setDeckIdxSelected] = useState<number | undefined>(
    undefined,
  )
  const [deckName, setDeckName] = useState<string | undefined>(undefined)
  const [deckColor, setDeckColor] = useState<string | undefined>(undefined)
  const [
    showCustomFlashcardDeckCreatorModal,
    setShowCustomFlashcardDeckCreatorModal,
  ] = useState<boolean>(false)

  const [duplicatedDeckName, setDuplicatedDeckName] = useState<boolean>(false)
  const [customFlashcards, setCustomFlashcards] = useState<
    Flashcard[] | undefined
  >(undefined)

  const [showColorPicker, setShowColorPicker] = useState(false)

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectFavorites, setSelectFavorites] = useState(false)

  const [checkedTags, setCheckedTags] = useState<string[]>([])

  const [selectedFlashcardsIds, setSelectedFlashcardsIds] = useState<string[]>(
    [],
  )

  const { mutate: deleteDeckAPI } = useDeleteDeck({
    mutation: {
      onSuccess: () => {
        deleteDeck()
      },
    },
  })

  const { mutate: updateDeckAPI } = useDeckUpdate({
    mutation: {
      onSuccess: (data) => {
        currentFlashcardsDeck!.name = data.name
        onDeckChanged()
      },
    },
  })

  const { mutate: updateLastStudiedAPI } = useUpdateDeckLastStudied({
    mutation: {
      onSuccess: () => {
        onDeckChanged()
      },
    },
  })

  const { data: tagsData, isLoading: isLoadingTagsData } = useTagsList()

  const tags = useMemo(() => {
    return tagsData?.results ?? []
  }, [tagsData])

  const onDeckChanged = () => {
    setShowEditModal(false)
    onCloseFlashcard()
  }

  const onEditDeckClicked = (index?: number) => {
    setDeckIdxSelected(index)
    setShowEditModal(true)
  }

  const onDeleteDeckClicked = (index: number) => {
    setShowDeleteModal(true)
    setDeckIdxSelected(index)
  }

  const onCancelCreateDeck = () => {
    setDuplicatedDeckName(false)
    setShowColorPicker(false)
    setShowEditModal(false)
    if (flashcardsDecks.length > 0) {
      setDeckIdxSelected(undefined)
    }
  }

  const updateDeck = (deck: Deck, newName: string, newColor: string) => {
    // check if the decks name is already in use and it's not the same deck
    const deckNameAlreadyInUse = flashcardsDecks.some(
      (item) => item.name === newName && item.id !== deck.id,
    )

    // if the name is already in use, show an error message on the form
    if (deckNameAlreadyInUse) {
      setDuplicatedDeckName(true)
      return
    }

    setDuplicatedDeckName(false)

    updateDeckAPI({
      deckId: deck!.id!,
      data: { ...deck, name: newName, color: newColor },
    })
  }

  const onSubmitFlashcardsDeckEdit: SubmitHandler<FormValues> = async () => {
    if (!deckName || !deckColor) {
      return
    }

    // If it's editing an existing flashcards deck
    if (currentFlashcardsDeck) {
      updateDeck(currentFlashcardsDeck, deckName, deckColor)
    }
  }

  const handleFilterFlashcardsByFavorites = (flashcards: Flashcard[]) => {
    return flashcards.filter((flashcard) => flashcard.favorite)
  }

  const handleFilterFlashcardsByTags = (
    flashcards: Flashcard[],
    checkedTags: string[],
  ) => {
    const checkIfStringExists = (array: string[], tags: Tag[]): boolean => {
      return tags.some((obj) => array.includes(obj.title!.toString()))
    }

    const filteredTags = tags.filter((tag) => checkedTags.includes(tag.id!))

    const tagsTitles = filteredTags.map((tag) => tag.title!)

    const filteredFlashcards = flashcards.filter((flashcard) => {
      if (!flashcard.tags) return false
      return checkIfStringExists(tagsTitles, flashcard.tags)
    })

    return filteredFlashcards
  }

  const filteredFlashcardsDecks = useMemo(() => {
    let filteredFlashcardsDecks = flashcardsDecks

    if (searchQuery) {
      filteredFlashcardsDecks = flashcardsDecks.filter((flashcardsDeck) =>
        flashcardsDeck.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    }

    if (selectFavorites) {
      filteredFlashcardsDecks = flashcardsDecks.map((flashcardsDeck) => {
        let filteredFlashcards = handleFilterFlashcardsByFavorites(
          flashcardsDeck.flashcards,
        )

        return { ...flashcardsDeck, flashcards: filteredFlashcards }
      })
    }

    if (checkedTags.length > 0) {
      filteredFlashcardsDecks = flashcardsDecks.map((flashcardsDeck) => {
        let filteredFlashcards = handleFilterFlashcardsByTags(
          flashcardsDeck.flashcards,
          checkedTags,
        )

        return { ...flashcardsDeck, flashcards: filteredFlashcards }
      })
    }

    filteredFlashcardsDecks = filteredFlashcardsDecks.filter(
      (flashcardsDeck) => flashcardsDeck.flashcards.length > 0,
    )

    return filteredFlashcardsDecks
  }, [flashcardsDecks, searchQuery, selectFavorites, checkedTags])

  const currentFlashcardsDeck = useMemo(() => {
    if (deckIdxSelected === undefined) {
      return undefined
    }
    return filteredFlashcardsDecks[deckIdxSelected]
  }, [filteredFlashcardsDecks, deckIdxSelected])

  const deleteDeck = () => {
    const newFlashcardsDeck = flashcardsDecks.filter(
      (item, index) => index !== deckIdxSelected,
    )
    setFlashcardsDecks(newFlashcardsDeck)
    setShowDeleteModal(false)
  }

  const onDeckSelected = (deck: Deck, deckIdx: number) => {
    setDeckIdxSelected(deckIdx)
    setShowFlashcardBoardModal(true)
    updateLastStudiedAPI({ deckId: deck.id! })
  }

  const onSubmitCustomFlashcardsDeck = () => {
    const customFlashcards = selectedFlashcardsIds.map((flashcardId) => {
      return decks
        .map((deck) => deck.flashcards)
        .flat()
        .find((flashcard) => flashcard.id === flashcardId)
    })

    if (customFlashcards.length === 0) {
      resetCustomFlashcardDeck()
      setShowCustomFlashcardDeckCreatorModal(false)
      setNotification({
        title: t('flashcards.errors.title'),
        message: t('flashcards.errors.noFlashcardsAvailable'),
        variant: 'danger',
        timeout: 5000,
      })
      return
    }

    setCustomFlashcards(customFlashcards)
    setShowCustomFlashcardDeckCreatorModal(false)
    setShowFlashcardBoardModal(true)
  }

  const resetCustomFlashcardDeck = () => {
    setCustomFlashcards(undefined)
    setSelectedFlashcardsIds([])
  }

  useEffect(() => {
    setFlashcardsDecks(decks)
  }, [decks])

  useEffect(() => {
    if (flashcardsDecks.length === 0) {
      setDeckIdxSelected(undefined)
      setDeckName(undefined)
    }
  }, [flashcardsDecks])

  useEffect(() => {
    setDeckName(currentFlashcardsDeck?.name)
    setDeckColor(currentFlashcardsDeck?.color)
  }, [currentFlashcardsDeck])

  return (
    <>
      <Row className="mb-4">
        <Col className="d-flex align-items-center">
          <SearchBar
            placeholderText={t('flashcards.searchDecks')}
            className={clsx(classes.searchBar, 'me-2')}
            onSearch={setSearchQuery}
            searchQuery={searchQuery}
          />
          <FlashcardsFilterDropdown
            tags={tags}
            onCheckedTagsChange={setCheckedTags}
            selectFavorites={selectFavorites}
            setSelectFavorites={setSelectFavorites}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            className={classes.addStuffButton}
            onClick={() => {
              setShowCustomFlashcardDeckCreatorModal(true)
            }}
          >
            {t('flashcards.createCustomFlashcardDeck')}
          </Button>
        </Col>
      </Row>

      {filteredFlashcardsDecks && filteredFlashcardsDecks.length > 0 ? (
        <Table headings={tableHeadings}>
          {' '}
          {filteredFlashcardsDecks.map((flashcardsDeck, deckIdx) => {
            if (flashcardsDeck.flashcards.length > 0) {
              return (
                <tr key={flashcardsDeck.id}>
                  <TableCell>
                    {flashcardsDeck.collection_name}
                  </TableCell>
                  <TableCell>
                    {flashcardsDeck.name}
                  </TableCell>
                  <TableCell>{flashcardsDeck.flashcards.length}</TableCell>
                  <TableCell>
                    {getTimeDifference(
                      t,
                      new Date(flashcardsDeck.last_studied),
                    )}
                  </TableCell>
                  <TableCell>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          {t('flashcards.study.start')}
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="default"
                        onClick={() => {
                          onDeckSelected(flashcardsDeck, deckIdx)
                        }}
                      >
                        <PlayBtn />
                      </Button>
                    </OverlayTrigger>
                  </TableCell>
                </tr>
              )
            }
          })}
        </Table>
      ) : (
        <div
          className={clsx(
            classes.flashcardsBoard,
            'd-flex justify-content-center align-items-center',
          )}
        >
          <Typography variant="h5">
            {t('flashcards.noFlashcards')}
          </Typography>
        </div>
      )}
      {
        showFlashcardBoardModal && (
          <Modal
            show={showFlashcardBoardModal}
            onHide={() => {
              setDeckIdxSelected(undefined)
              resetCustomFlashcardDeck()
              setShowFlashcardBoardModal(false)
            }}
            size="xl"
            backdrop="static"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{t('flashcards.study.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.modalBody}>
              <FlashcardStudyBoard
                flashcards={
                  currentFlashcardsDeck
                    ? currentFlashcardsDeck?.flashcards
                    : customFlashcards ?? []
                }
                onClose={() => {
                  setNotification({
                    title: t('flashcards.study.title'),
                    message: t('flashcards.study.flashcardsCompleted'),
                    variant: 'success',
                    timeout: 5000,
                  })
                  setShowFlashcardBoardModal(false)
                }}
              />
            </Modal.Body>
          </Modal>
        )
      }
      <EditDeckModal
        showEditModal={showEditModal}
        deckIdxSelected={deckIdxSelected}
        currentFlashcardsDeck={currentFlashcardsDeck}
        onSubmit={handleSubmit(onSubmitFlashcardsDeckEdit)}
        onCancelCreateDeck={onCancelCreateDeck}
        duplicatedDeckName={duplicatedDeckName}
        deckName={deckName}
        setDeckName={setDeckName}
        deckColor={deckColor}
        setDeckColor={setDeckColor}
        showColorPicker={showColorPicker}
        setShowColorPicker={setShowColorPicker}
        setDuplicatedDeckName={setDuplicatedDeckName}
      />
      <Modal show={showEditModal} onHide={onCancelCreateDeck} centered>
        <Modal.Header>
          <Modal.Title>
            {deckIdxSelected !== undefined
              ? t('flashcards.editFlashcardDeck')
              : t('flashcards.createFlashcardDeck')}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmitFlashcardsDeckEdit)}>
          <Modal.Body>
            <div>
              <Form.Label>{t('common.name')}</Form.Label>
              <Form.Control
                type="text"
                value={deckName ?? currentFlashcardsDeck?.name}
                onChange={(e) => {
                  setDeckName(e.target.value)
                }}
              />
              {duplicatedDeckName && (
                <Form.Text className="text-danger">
                  {t('flashcards.errors.duplicatedDeckName')}
                </Form.Text>
              )}
            </div>
            <div className="d-flex align-items-center mt-4 mb-2">
              <div>{t('common.color')}</div>
              <Button
                variant="white"
                className={clsx(classes.deckColorPickerContainer)}
                onClick={() => setShowColorPicker(!showColorPicker)}
              >
                <div
                  className={clsx(classes.circle)}
                  style={{
                    backgroundColor: deckColor,
                    width: 15,
                    height: 15,
                    border: '0.5px solid rgb(33, 37, 41)',
                  }}
                ></div>
              </Button>
            </div>
            {showColorPicker && (
              <ColorPicker
                colorPickerComponent={
                  <CirclePicker
                    show={showColorPicker}
                    className={clsx(classes.colorPicker)}
                    color={deckColor}
                    colors={[
                      material.red['500'],
                      material.pink['500'],
                      material.purple['500'],
                      material.lightBlue['500'],
                      material.teal['500'],
                      material.lightGreen['500'],
                      material.yellow['500'],
                      material.orange['500'],
                    ]}
                    onChangeComplete={(color: {
                      rgb: { r: number; g: number; b: number; a: number }
                    }) => {
                      const rgbColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`
                      setDeckColor(rgbColor)
                    }}
                  />
                }
                show={showColorPicker}
                onClose={() => setShowColorPicker(false)}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={() => onCancelCreateDeck()}>
              {t('common.cancel')}
            </Button>
            <Button variant="success" type="submit">
              {t('common.save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{t('flashcards.deleteFlashcardDeck')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('flashcards.deleteFlashcardDeckDescription')}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            onClick={() => {
              setShowDeleteModal(false)
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={() =>
              deleteDeckAPI({ deckId: currentFlashcardsDeck!.id! })
            }
          >
            {t('common.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCustomFlashcardDeckCreatorModal}
        onHide={() => {
          resetCustomFlashcardDeck()
          setShowCustomFlashcardDeckCreatorModal(false)
        }}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('flashcards.createCustomFlashcardDeck')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmitCustomFlashcardsDeck)}>
          <Modal.Body>
            <FlashcardsBoard
              addMode={false}
              initialEditMode={false}
              selectingMode={true}
              selectedFlashcardsIds={selectedFlashcardsIds}
              setSelectedFlashcardsIds={setSelectedFlashcardsIds}
              onCloseFlashcard={() => {
                setShowCustomFlashcardDeckCreatorModal(false)
                resetCustomFlashcardDeck()
              }}
              decks={flashcardsDecks}
            />
          </Modal.Body>
        </Form>
      </Modal>
    </>
  )
}

export default FlashcardsStudyBoard
