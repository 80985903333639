const convertObjectArrayIntoStringArray = (
  items: any[] | undefined,
  key: string,
) => {
  if (!items) return []

  return items.map(function (item) {
    return item[key]
  })
}

export default convertObjectArrayIntoStringArray
