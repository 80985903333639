const removeEmptyPTags = (html: string): string => {
  // Parse the HTML string into a DOM Document
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  // Get all <p> elements
  const pTags = doc.querySelectorAll('p')

  // Iterate over the <p> elements and remove empty ones
  pTags.forEach((p) => {
    if (!p.textContent?.trim()) {
      p.parentNode?.removeChild(p)
    }
  })

  // Serialize the document back to an HTML string
  return doc.body.innerHTML
}

export default removeEmptyPTags
