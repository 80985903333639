import Axios, { AxiosRequestConfig } from 'axios'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.VITE_API_BASE_URL,
})

AXIOS_INSTANCE.interceptors.request.use((config) => {
  const params = new URLSearchParams(window.location.search)
  const token = params.has('token') ? params.get('token') : cookies.get('token')

  if (token && config?.headers) config.headers.Authorization = `Token ${token}`
  return config
})

export const instance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(
    ({ data }) => data,
  )
  return promise
}
