/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * qb-api
 * QB API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation
} from 'react-query'
import type {
  MutationFunction,
  UseMutationOptions
} from 'react-query'
import type {
  UploadImage200,
  UploadImageBody
} from '.././model'
import { instance } from '.././instance';



/**
 * @summary Upload file
 */
export const uploadImage = (
    uploadImageBody: UploadImageBody,
 ) => {
      
      const formData = new FormData();
if(uploadImageBody.image !== undefined) {
 formData.append('image', uploadImageBody.image)
 }

      return instance<UploadImage200>(
      {url: `/user/upload`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getUploadImageMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadImage>>, TError,{data: UploadImageBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadImage>>, TError,{data: UploadImageBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadImage>>, {data: UploadImageBody}> = (props) => {
          const {data} = props ?? {};

          return  uploadImage(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadImageMutationResult = NonNullable<Awaited<ReturnType<typeof uploadImage>>>
    export type UploadImageMutationBody = UploadImageBody
    export type UploadImageMutationError = unknown

    /**
 * @summary Upload file
 */
export const useUploadImage = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadImage>>, TError,{data: UploadImageBody}, TContext>, }
) => {

      const mutationOptions = getUploadImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    