import '@fontsource/inter'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ReactNode, FC } from 'react'

interface ThemeProviderProps {
  children: ReactNode
}

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => <>{children}</>

export default ThemeProvider
