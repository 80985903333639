import clsx from 'clsx'
import { Fragment, MouseEventHandler, FC, forwardRef } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import Spinner from 'react-bootstrap/Spinner'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UserIcon } from '../../assets/icons'
import { NavItem } from '../../molecules'
import navItems from '../../utils/navItems'
import classes from './BottombarItems.module.css'
import { Typography } from '../../atoms'
import { UserInfo200 } from '../../api/model/userInfo200'

const CustomToggle = forwardRef<
  any,
  { onClick: MouseEventHandler<HTMLDivElement> }
>(({ onClick }, ref) => (
  <div ref={ref} onClick={onClick} className="d-flex justify-content-center">
    <NavItem
      icon={UserIcon}
      description="navigation.user"
      smallScreen
      size={20}
    />
  </div>
))

interface BottombarItemsProps {
  userInfo?: UserInfo200
  isLoading: boolean
}

const BottombarItems: FC<BottombarItemsProps> = ({ userInfo, isLoading }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [, , removeCookie] = useCookies([
    'token',
    'seen-promotional-campaigns',
    'seeTutorialLater',
  ])

  const handleLogout = () => {
    queryClient.clear()
    removeCookie('token')
    removeCookie('seen-promotional-campaigns')
    removeCookie('seeTutorialLater')
    console.log('Logout')
  }

  return (
    <>
      {navItems.map(({ slug, description, to, icon }) => (
        <Fragment key={slug}>
          <Link
            to={to}
            className={clsx(
              'd-flex',
              'align-items-center',
              'justify-content-center',
              classes.resetLink,
            )}
          >
            <NavItem
              description={description}
              icon={icon}
              selected={location.pathname.includes(to)}
              smallScreen
              size={20}
            />
          </Link>
        </Fragment>
      ))}
      <Dropdown className="d-inline" drop="up" align="start">
        <Dropdown.Toggle as={CustomToggle} />

        <Dropdown.Menu className={classes.dropDownPosition}>
          <Dropdown.Header>
            {isLoading ? (
              <Spinner animation="border" variant="success" size="sm" />
            ) : (
              <Typography>
                {`${userInfo?.first_name} ${userInfo?.last_name}`}
              </Typography>
            )}
          </Dropdown.Header>
          <Dropdown.Item onClick={() => navigate('/plans')}>
            {t('user.account')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleLogout}>
            {t('user.logout')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default BottombarItems
