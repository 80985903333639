import clsx from "clsx";
import { FC, useContext, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { FlashcardsBoard, FlashcardsStudyBoard } from "..";
import { FlashcardCollection } from "../../api/model";
import { Divider, Typography } from "../../atoms";
import { HighlightedTextContext } from "../FlashcardCollectionsList/FlashcardCollectionsList";
import classes from './DeckMenu.module.css';

interface DecksMenuProps {
    collection: FlashcardCollection;
    setFlashcardCollectionIdSelected: (collectionId: string | undefined) => void;
}

const DecksMenu: FC<DecksMenuProps> = ({
    collection,
    setFlashcardCollectionIdSelected
}) => {
    const { t } = useTranslation()

    const { highlightedText, onPastedText } = useContext(HighlightedTextContext)

    const initialEditMode = useMemo(() => highlightedText.length !== 0, [highlightedText])

    return (
        <>
            <Row className="mt-4 mb-4 align-items-center">
                <Col className="d-flex align-items-center">
                    <div className="me-2">
                        <ArrowLeft className="me-2" style={{ cursor: "pointer" }} onClick={() => setFlashcardCollectionIdSelected(undefined)} />
                    </div>
                    <div
                        className={clsx(classes.circle)}
                        style={{
                            backgroundColor: collection?.color,
                            width: 20,
                            height: 20,
                            marginRight: 10,
                            padding: 5,
                            border: '0.5px solid rgba(0, 0, 0, 0.176)',
                        }}
                    />
                    <Typography
                        variant="h4"
                        fontWeight="light"
                        className="mb-0"
                    >
                        {collection?.title}
                    </Typography>
                </Col>
            </Row>

            <Row className="ms-2">
                <Col>
                    <FlashcardsBoard
                        initialEditMode={initialEditMode}
                        addMode={true}
                        editDeckMode={true}
                        collectionId={collection.id!}
                        highlightedText={highlightedText}
                        onCloseFlashcard={() => onPastedText()}
                        decks={collection.decks}
                    />
                </Col>
            </Row>
        </>
    )
}

export default DecksMenu;