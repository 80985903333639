import clsx from 'clsx'
import { ReactNode, FC } from 'react'
import TableBs from 'react-bootstrap/Table'
import { useTranslation } from 'react-i18next'
import { Typography } from '../../atoms'
import useBreakpoint from '../../hooks/useBreakpoint'
import classes from './Table.module.css'

export interface TableProps {
  children?: ReactNode
  headings: {
    slug: string
    content: string | ReactNode
    className?: string
  }[]
}

const Table: FC<TableProps> = ({ children, headings }) => {
  const { t } = useTranslation()
  const size = useBreakpoint()

  return (
    <div className="table-responsive">
      <TableBs
        className={clsx({ [classes.fontSize]: size === 'sm' || size === 'xs' })}
      >
        <thead>
          <tr>
            {headings.map((heading) => (
              <th className={heading.className} key={heading.slug}>
                {typeof heading.content === 'string' ? (
                  <Typography className="text-secondary">
                    {t(heading.content)}
                  </Typography>
                ) : (
                  heading.content
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </TableBs>
    </div>
  )
}

export default Table
